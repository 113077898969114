import { Checkbox, Divider, Grid, IconButton, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { Save as SaveIcon, Add as AddIcon } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { uniqueId } from 'lodash';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { DefinitionFieldFlag } from '../../constants';
import {
	CreatePlacementDefinitionRequestBody,
	Definition,
	Field,
} from '../../DevicePlacementsManagement/device-placements-types';
import { Autocomplete } from '@material-ui/lab';
import { getProjects } from '../../../services/projects/projects-service';
import { TableColumnFilter } from '../../../components/Table/types';
import { ProjectResponseData, ProjectsResponseData } from '../../Projects/projects-types';
import { fetchRequest } from '../../../services/helpers';
import { FilterOperators } from '../../../components/Table/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	select: {
		'& .MuiFormLabel-root': {
			width: '90%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			paddingRight: spacing(2),

			'&.Mui-focused': {
				width: '100%',
				textOverflow: 'initial',
			},
		},
	},
}));

export type PlacmentDefinitionFormValues = {
	name: string;
	placementFields: Field[];
	defaultValue?: Definition;
};

type Props = {
	onSubmit: (values: CreatePlacementDefinitionRequestBody) => void;
	onCancel: () => void;
	defaultValues?: Definition;
};

type TitleFlag = { label: string; id: string; bit?: number };

type DefinitionField = {
	name: string;
	key: string;
	errorFieldName: boolean;
	mandatory: boolean;
	titleFlag: TitleFlag | undefined;
};

const titleFlags: TitleFlag[] = [
	// { label: DefinitionFieldFlag[DefinitionFieldFlag.None], id: DefinitionFieldFlag.None.toString() },
	{
		label: DefinitionFieldFlag[DefinitionFieldFlag.Title1],
		id: DefinitionFieldFlag.Title1.toString(),
		bit: DefinitionFieldFlag.Title1,
	},
	{
		label: DefinitionFieldFlag[DefinitionFieldFlag.Title2],
		id: DefinitionFieldFlag.Title2.toString(),
		bit: DefinitionFieldFlag.Title2,
	},
	{
		label: DefinitionFieldFlag[DefinitionFieldFlag.SubTitle1],
		id: DefinitionFieldFlag.SubTitle1.toString(),
		bit: DefinitionFieldFlag.SubTitle1,
	},
	{
		label: DefinitionFieldFlag[DefinitionFieldFlag.SubTitle2],
		id: DefinitionFieldFlag.SubTitle2.toString(),
		bit: DefinitionFieldFlag.SubTitle2,
	},
];

export const PlacementDefinitionForm = ({ onSubmit, onCancel, defaultValues }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const { tenant } = useSelector<RootState, UserState>((state) => state.user);

	const [name, setName] = useState<string>(defaultValues?.name ?? '');
	const [errorName, setErrorName] = useState<boolean>(false);

	const [allProjects, setAllProjects] = useState<ProjectResponseData[]>([]);
	const [selectedProjects, setSelectedProjects] = useState(defaultValues?.projects ?? []);

	let defaultFields: DefinitionField[] = [];

	if (defaultValues?.placementFields) {
		defaultFields = defaultValues?.placementFields.map((field) => {
			console.log(field);

			const titleFlag = titleFlags.find((titleFlag) => {
				if (titleFlag.bit && field.flagsValue) {
					return (titleFlag.bit & field.flagsValue) === titleFlag.bit;
				} else {
					return false;
				}
			});

			return {
				name: field.name,
				key: uniqueId(),
				errorFieldName: false,
				mandatory: field.flagsValue ? (field.flagsValue & DefinitionFieldFlag.Mandatory) > 0 : false,
				titleFlag: titleFlag,
			};
		});
	}

	const [fields, setFields] = useState<DefinitionField[]>(defaultFields);

	/**
	 * Fetching all projects
	 */
	useEffect(() => {
		const filters: TableColumnFilter[] = [];

		if (tenant?.id) {
			filters.push({ column: 'userId', operator: FilterOperators.ignore, value: 0 });
		}
		//  else {
		// 	if (userDetailData?.tenantId) {
		// 		filters.push({ column: 'tenantId', operator: FilterOperators.equals, value: userDetailData?.tenantId });
		// 	}
		// }

		const request = getProjects({
			// Should be enough to get all zones, even for super admin
			limit: 0,
			offset: 0,
			orderings: [],
			filtersAndConditions: filters,
		});

		(async () => {
			const { data, error } = await fetchRequest<ProjectsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setAllProjects(data?.data ?? []);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAddField = () => {
		fields.push({ name: '', key: uniqueId(), errorFieldName: false, mandatory: false, titleFlag: undefined });
		setFields([...fields]);
	};

	function handleFieldRemove(key: string) {
		setFields(fields.filter((field) => field.key !== key));
	}

	function handleFieldMoveUp(key: string) {
		const index = fields.findIndex((field) => field.key === key);

		if (index > 0) {
			const element = fields.splice(index, 1);

			fields.splice(index - 1, 0, element[0]);

			setFields([...fields]);
		}
	}

	function handleFieldMoveDown(key: string) {
		const index = fields.findIndex((field) => field.key === key);

		if (index < fields.length - 1) {
			const element = fields.splice(index, 1);

			fields.splice(index + 1, 0, element[0]);

			setFields([...fields]);
		}
	}

	function handleName(text: string) {
		setName(text);

		if (errorName) {
			setErrorName(false);
		}
	}

	const handleProjects = (event: unknown, newValue: ProjectResponseData[]) => {
		// const mask: string[] = Array(permissions.length).fill('0');

		// newValue.forEach((permission) => (mask[permission.index] = '1'));

		setSelectedProjects(newValue);

		console.log(newValue);
	};

	function handleFieldName(text: string, field: DefinitionField) {
		field.name = text;

		if (field.errorFieldName === true) {
			field.errorFieldName = false;
			setFields([...fields]);
		}
	}

	function handleMandatoryFlag(mandatory: boolean, field: DefinitionField) {
		field.mandatory = mandatory;
		setFields([...fields]);
	}

	function handleTitleFlag(titleFlag: TitleFlag | null, field: DefinitionField) {
		field.titleFlag = titleFlag === null ? undefined : titleFlag;
	}

	const handleSubmitForm = (event: any) => {
		console.log(fields);

		let valid = true;

		if (name === undefined || name === '') {
			valid = false;
			setErrorName(true);
		}

		fields.forEach((field) => {
			if (field.name !== undefined && field.name !== '') {
				console.log(field.key);
			} else {
				valid = false;
				field.errorFieldName = true;
			}
		});

		if (valid) {
			console.log('valid save');

			const toSave: Field[] = fields.map((field) => {
				const flags: number =
					(field.mandatory ? DefinitionFieldFlag.Mandatory : 0) |
					(field.titleFlag?.bit === undefined ? 0 : field.titleFlag.bit);

				return {
					name: field.name,
					type: 'string',
					flags: flags,
				};
			});

			console.log(toSave);

			onSubmit({ name: name, projects: selectedProjects, placementFields: toSave });
		} else {
			console.log('not valid');
			setFields([...fields]);
		}

		event.preventDefault();
	};

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputField
					key={'definition.name'}
					error={errorName}
					helperText={errorName && t('validationErrors.required')}
					label={t('definition.name')}
					margin="none"
					name={'definition.name'}
					defaultValue={name}
					onChange={(event: any) => handleName(event.target.value)}
					required
				/>
			</Grid>
			<Grid item>
				<Autocomplete
					className={classes.select}
					color="primary"
					multiple
					options={allProjects}
					getOptionLabel={(option) => option.name}
					defaultValue={selectedProjects}
					filterSelectedOptions
					disableCloseOnSelect
					fullWidth
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('selectProjects')}
							// placeholder="Favorites"
							// error={permissionsError}
							// helperText={permissionsError && t('validationErrors.required')}
							variant="outlined"
						/>
					)}
					onChange={handleProjects}
				/>
			</Grid>
			{fields.map((field) => (
				<Grid item key={field.key}>
					<Grid container spacing={2} direction="column">
						<Grid item>
							<Divider />
						</Grid>
						<Grid item>
							<Grid container spacing={2} direction="row">
								<Grid item xs={4}>
									<InputField
										error={field.errorFieldName}
										helperText={field.errorFieldName && t('validationErrors.required')}
										label={t('field.name')}
										margin="none"
										name={'field.name'}
										defaultValue={field.name}
										onChange={(event: any) => handleFieldName(event.target.value, field)}
										required
									/>
								</Grid>
								<Grid item xs={2}>
									<Grid container alignContent="center" alignItems="center" direction="row">
										<Grid item>
											<Checkbox
												color="primary"
												// size="small"
												// indeterminate={numSelectedRows > 0 && numSelectedRows < rowCount}
												checked={field.mandatory}
												onChange={(
													event: React.ChangeEvent<HTMLInputElement>,
													checked: boolean,
												) => handleMandatoryFlag(checked, field)}
											/>
										</Grid>
										<Grid item>
											<Typography variant="body2" color="inherit">
												Man.
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={3}>
									<InputSelectUncontrolled
										defaultValueId={field.titleFlag?.id}
										onChanged={(value) => {
											handleTitleFlag(value, field);
										}}
										error={false}
										options={titleFlags}
										label={t('selectTitle')}
									/>
								</Grid>
								<Grid item alignContent="flex-end" xs={3}>
									<IconButton
										edge="start"
										color="inherit"
										aria-label="open drawer"
										onClick={() => handleFieldMoveUp(field.key)}
									>
										<ArrowUpwardIcon />
									</IconButton>
									<IconButton
										edge="start"
										color="inherit"
										aria-label="open drawer"
										onClick={() => handleFieldMoveDown(field.key)}
									>
										<ArrowDownwardIcon />
									</IconButton>
									<IconButton
										edge="start"
										color="inherit"
										aria-label="open drawer"
										onClick={() => handleFieldRemove(field.key)}
									>
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			))}
			<Grid item>
				<Button
					onClick={handleAddField}
					startIcon={<AddIcon />}
					data-cy="btn-add:addField"
					fullWidth={false}
					variant="outlined"
				>
					{t('addField')}
				</Button>
			</Grid>
			{/* <Grid item>
				<InputSelectUncontrolled
					defaultValueId={types[0]}
					onChanged={(value) => {
						value && setSelectedExportType(value.id);
					}}
					error={false}
					options={
						types.map((exportType) => ({
							id: exportType,
							label: exportType,
						})) ?? []
					}
					label={t('selectExportType')}
					name="id"
				/>
			</Grid> */}
			<Grid item>
				<ModalDialogFormActions>
					<Button onClick={handleSubmitForm} startIcon={<SaveIcon />} data-cy="btn-submit:exportDevicesForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:exportDevicesForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};
