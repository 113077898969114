import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import { store } from '../store/configure-store';
import { RequestHeaders } from '../helpers/generalConstants';
import { setRedirectUrl } from '../store/app/app-actions';
import { RouteEnum } from '../router/Routes';

const BASE_URL = new Map([
	['mock', 'http://localhost:4001'],
	['development', 'http://localhost:3000/api'],
	['test', 'http://185.77.145.57:3000/api/'],
	['qa', 'https://app.gorebit.com:3100/api/'],
	['production', 'https://slovanet-asset.isdd.sk/api/'],
	['lab', 'https://lab.gorebit.com:3100/api/'],
]);

const SOCKET_URL = new Map([
	['mock', 'http://localhost:4001'],
	['development', 'http://localhost:3000/api'],
	['test', 'http://185.77.145.57:3000'],
	['qa', 'https://app.gorebit.com:3100'],
	['production', 'https://slovanet-asset.isdd.sk'],
	['lab', 'https://lab.gorebit.com:3100'],
]);

const ENVIRONMENT: string = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';
export const socketUrl = SOCKET_URL.get(ENVIRONMENT) || 'http://slovanet-asset.isdd.sk:3000';
export const baseUrl = BASE_URL.get(ENVIRONMENT);

export const apiClient = axios.create({
	baseURL: BASE_URL.get(ENVIRONMENT),
	responseType: 'json',
	headers: {
		'Content-Type': 'application/json',
	},
	paramsSerializer: function (params) {
		return stringify(params, { arrayFormat: 'comma' });
	},
});

export const apiClientForExport = axios.create({
	baseURL: BASE_URL.get(ENVIRONMENT),
	responseType: 'blob',
	headers: {
		'Content-Type': 'application/json',
	},
});

export const apiClientForImport = axios.create({
	baseURL: BASE_URL.get(ENVIRONMENT),
	headers: {
		'Content-Type': 'application/octet-stream',
	},
});

apiClient.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		const authHeaderRequired: boolean = config.url !== '/identity/login';

		if (authHeaderRequired) {
			const token = store.getState()?.user.jwtToken;

			config.headers[RequestHeaders.AUTH] = `Bearer ${token}`;
		}

		return config;
	},
	(error: AxiosError) => Promise.reject(error),
);

apiClient.interceptors.response.use(
	(response: AxiosResponse) => response,
	(error: AxiosError) => {
		const isUnauthorized = error.response?.status === 401;

		if (isUnauthorized) {
			window.localStorage.clear();
			window.sessionStorage.clear();
			store.dispatch(setRedirectUrl(RouteEnum.LOGIN));
		}

		return Promise.reject(error);
	},
);

apiClientForExport.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		const authHeaderRequired: boolean = config.url !== '/identity/login';

		if (authHeaderRequired) {
			const token = store.getState()?.user.jwtToken;

			config.headers[RequestHeaders.AUTH] = `Bearer ${token}`;
		}

		return config;
	},
	(error: AxiosError) => Promise.reject(error),
);

apiClientForExport.interceptors.response.use(
	(response: AxiosResponse) => response,
	(error: AxiosError) => {
		const isUnauthorized = error.response?.status === 401;

		if (isUnauthorized) {
			window.localStorage.clear();
			window.sessionStorage.clear();
			store.dispatch(setRedirectUrl(RouteEnum.LOGIN));
		}

		return Promise.reject(error);
	},
);

apiClientForImport.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		const authHeaderRequired: boolean = config.url !== '/identity/login';

		if (authHeaderRequired) {
			const token = store.getState()?.user.jwtToken;

			config.headers[RequestHeaders.AUTH] = `Bearer ${token}`;
		}

		return config;
	},
	(error: AxiosError) => Promise.reject(error),
);

apiClientForImport.interceptors.response.use(
	(response: AxiosResponse) => response,
	(error: AxiosError) => {
		const isUnauthorized = error.response?.status === 401;

		if (isUnauthorized) {
			window.localStorage.clear();
			window.sessionStorage.clear();
			store.dispatch(setRedirectUrl(RouteEnum.LOGIN));
		}

		return Promise.reject(error);
	},
);
