import { createReducer } from 'typesafe-actions';
import {
	REVIEW_COMMON_EMPTY_REQUEST,
	REVIEW_COMMON_EMPTY_ERROR,
	FETCH_REPORT_ORDERS_SUCCESS,
	CREATE_REPORT_ORDER_SUCCESS,
	EDIT_REPORT_ORDER_SUCCESS,
	DELETE_REPORT_ORDER_SUCCESS,
	GET_REPORT_ORDER_DEFINITION_SUCCESS,
	GET_REPORT_ORDER_DEFINITIONS_NAMES_SUCCESS,
	FETCH_STANDING_REPORT_ORDERS_SUCCESS,
	CREATE_STANDING_REPORT_ORDER_SUCCESS,
	EDIT_STANDING_REPORT_ORDER_SUCCESS,
	DELETE_STANDING_REPORT_ORDER_SUCCESS,
} from './review-constants';

import { ReviewActions, ReviewState } from './review-types';
import { updateObjectInArray } from '../../helpers/array';
import { SortOrder } from '../../components/Table/constants';

export const reviewInitialState: ReviewState = {
	data: [],
	total: 0,
	limit: 10,
	offset: 0,
	orderings: [
		{
			column: 'dateCreated',
			sortOrder: SortOrder.Descending,
		},
	],
	filters: [],
	definitionsNames: [],
	standingOrders: {
		data: [],
		total: 0,
		limit: 10,
		offset: 0,
		orderings: [],
		filters: [],
	},
};

export const reviewReducer = createReducer<ReviewState, ReviewActions>(reviewInitialState, {
	[REVIEW_COMMON_EMPTY_REQUEST]: (state) => state,
	[REVIEW_COMMON_EMPTY_ERROR]: (state) => state,
	[FETCH_REPORT_ORDERS_SUCCESS]: (state, action) => ({
		...state,
		data: action.payload.data ? action.payload.data.data : state.data,
		total: action.payload.data ? action.payload.data.total : state.total,
		limit: action.payload.options ? action.payload.options.limit : state.limit,
		offset: action.payload.options ? action.payload.options.offset : state.offset,
		orderings: action.payload.options?.orderings,
		filters: action.payload.options?.filtersAndConditions,
	}),
	[CREATE_REPORT_ORDER_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const reportOrderIndex = state.data.findIndex((reportOrder) => reportOrder.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, reportOrderIndex, state.data),
		};
	},
	[EDIT_REPORT_ORDER_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const reportOrderIndex = state.data.findIndex((reportOrder) => reportOrder.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, reportOrderIndex, state.data),
		};
	},
	[DELETE_REPORT_ORDER_SUCCESS]: (state, action) => {
		return {
			...state,
			data: state.data.filter((reportOrder) => reportOrder.id !== action.payload.id),
		};
	},
	[GET_REPORT_ORDER_DEFINITION_SUCCESS]: (state, action) => {
		return {
			...state,
			zoneDetailData: action.payload.data,
		};
	},
	[GET_REPORT_ORDER_DEFINITIONS_NAMES_SUCCESS]: (state, action) => {
		return {
			...state,
			definitionsNames: action.payload.data,
		};
	},
	[FETCH_STANDING_REPORT_ORDERS_SUCCESS]: (state, action) => ({
		...state,
		standingOrders: {
			data: action.payload.data ? action.payload.data.data : state.standingOrders.data,
			total: action.payload.data ? action.payload.data.total : state.standingOrders.total,
			limit: action.payload.options ? action.payload.options.limit : state.standingOrders.limit,
			offset: action.payload.options ? action.payload.options.offset : state.standingOrders.offset,
			orderings: action.payload.options?.orderings,
			filters: action.payload.options?.filtersAndConditions,
		},
	}),
	[CREATE_STANDING_REPORT_ORDER_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const reportOrderIndex = state.standingOrders.data.findIndex((reportOrder) => reportOrder.id === data.id);

		return {
			...state,
			standingOrders: {
				...state.standingOrders,
				data: updateObjectInArray(data, reportOrderIndex, state.standingOrders.data),
			},
		};
	},
	[EDIT_STANDING_REPORT_ORDER_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const reportOrderIndex = state.standingOrders.data.findIndex((reportOrder) => reportOrder.id === data.id);

		return {
			...state,
			standingOrders: {
				...state.standingOrders,
				data: updateObjectInArray(data, reportOrderIndex, state.standingOrders.data),
			},
		};
	},
	[DELETE_STANDING_REPORT_ORDER_SUCCESS]: (state, action) => {
		return {
			...state,
			standingOrders: {
				...state.standingOrders,
				data: state.standingOrders.data.filter((reportOrder) => reportOrder.id !== action.payload.id),
			},
		};
	},
});
