import { DevicesState, DevicesActions } from './devices-types';
import { createReducer } from 'typesafe-actions';
import {
	GENERAL_DEVICE_REQUEST,
	GENERAL_DEVICE_ERROR,
	CREATE_DEVICE_SUCCESS,
	UPLOAD_DEVICES_SUCCESS,
	FETCH_DEVICES_TELEMETRY_SUCCESS,
	FETCH_DEVICE_SUCCESS,
	SET_DEVICES_TABLE_HIDEN_FLAGS_SUCCESS,
	SET_TELEMETRY_TABLE_HIDEN_FLAGS_SUCCESS,
	FETCH_DEVICES_TELEMETRY_SUCCESS_NO_PROGRESS_BAR,
	FETCH_DEVICE_SUCCESS_NO_PROGRESS_BAR,
	FETCH_DEVICE_TYPES_SUCCESS,
	CREATE_DEVICE_TYPE_SUCCESS,
	EDIT_DEVICE_TYPE_SUCCESS,
	DELETE_DEVICE_TYPE_SUCCESS,
	EDIT_DEVICE_SUCCESS,
	EDIT_DEVICE_PROPERTIES_SUCCESS,
} from './devices-constants';
import { filter, set } from 'lodash/fp';

// const defaultOptions = {
// 	config: {
// 		offset: 0,
// 		limit: 10,
// 		order: SortOrder.Ascending,
// 		orderBy: 'sn',
// 	},
// 	filters: {
// 		deviceGroupId: [],
// 		sn: [],
// 	},
// };

// const deviceTelemetryOptions = {
// 	config: {
// 		offset: 0,
// 		limit: 10,
// 		order: SortOrder.Ascending,
// 		orderBy: 'lastSeen', // compiles to "last_telemetry -> 'time'"
// 	},
// 	filters: {
// 		battery: [],
// 		lightIntensity: [],
// 		sn: [],
// 		temperature: [],
// 		type: [],
// 	},
// };

const devicesInitialState: DevicesState = {
	offset: 0,
	limit: 10,
	orderings: [], // TODO
	filtersAndConditions: [], // TODO
	data: [],
	total: 0,
	deviceDetail: undefined,
	deviceTableHidenFlags: [],
	telemetryTableHidenFlags: [],
	deviceTypes: {
		offset: 0,
		limit: 10,
		orderings: [], // TODO
		filtersAndConditions: [], // TODO
		data: [],
		total: 0,
	},
};

const fetchDeviceTelemetrySuccess = (state: DevicesState, action: any) => ({
	...state,
	data: action.payload.data.data,
	total: action.payload.data.total,
	limit: action.payload.options ? action.payload.options.limit : state.limit,
	offset: action.payload.options ? action.payload.options.offset : state.offset,
	orderings: action.payload.options?.orderings || state.orderings,
	filtersAndConditions: action.payload.options?.filtersAndConditions || state.filtersAndConditions,
	updatedTime: new Date().toISOString(),
});

export const devicesReducer = createReducer<DevicesState, DevicesActions>(devicesInitialState, {
	[GENERAL_DEVICE_REQUEST]: (state) => state,
	[GENERAL_DEVICE_ERROR]: (state) => state,
	[CREATE_DEVICE_SUCCESS]: (state) => state,
	[EDIT_DEVICE_SUCCESS]: (state) => state,
	[UPLOAD_DEVICES_SUCCESS]: (state) => state,
	[FETCH_DEVICES_TELEMETRY_SUCCESS]: fetchDeviceTelemetrySuccess,
	[FETCH_DEVICES_TELEMETRY_SUCCESS_NO_PROGRESS_BAR]: fetchDeviceTelemetrySuccess,
	[FETCH_DEVICE_SUCCESS]: (state, action) => set('deviceDetail', action.payload.data, state),
	[FETCH_DEVICE_SUCCESS_NO_PROGRESS_BAR]: (state, action) => set('deviceDetail', action.payload.data, state),
	[SET_DEVICES_TABLE_HIDEN_FLAGS_SUCCESS]: (state, action) =>
		set('deviceTableHidenFlags', action.payload.data, state),
	[SET_TELEMETRY_TABLE_HIDEN_FLAGS_SUCCESS]: (state, action) =>
		set('telemetryTableHidenFlags', action.payload.data, state),
	[FETCH_DEVICE_TYPES_SUCCESS]: (state, action) => {
		return {
			...state,
			deviceTypes: {
				data: action.payload.data.data,
				total: action.payload.data.total,
				limit: action.payload.options ? action.payload.options.limit : state.deviceTypes.limit,
				offset: action.payload.options ? action.payload.options.offset : state.deviceTypes.offset,
				orderings: action.payload.options?.orderings || state.deviceTypes.orderings,
				filtersAndConditions:
					action.payload.options?.filtersAndConditions || state.deviceTypes.filtersAndConditions,
			},
		};
	},
	[CREATE_DEVICE_TYPE_SUCCESS]: (state) => state,
	[EDIT_DEVICE_TYPE_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const deviceTypeIndex = state.deviceTypes.data.findIndex((deviceType) => deviceType.id === data.id);

		return set(`deviceTypes.data[${deviceTypeIndex}]`, data, state);
	},
	[DELETE_DEVICE_TYPE_SUCCESS]: (state, action) =>
		set(
			'deviceTypes.data',
			filter((deviceType) => deviceType.id !== action.payload.id, state.deviceTypes.data),
			state,
		),
	[EDIT_DEVICE_PROPERTIES_SUCCESS]: (state) => state,
});
