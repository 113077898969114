import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { Grid } from '@material-ui/core';
import { DeviceProperties } from '../devices-types';
import { UUID } from '../../../types';

export type DevicePropertiesFormValues = {
	deviceId: UUID;
	deviceProperty: DeviceProperties;
};

type Props = {
	values: DevicePropertiesFormValues;
	onSubmit: (values: DevicePropertiesFormValues) => void;
	onCancel?: () => void;
};

export const DevicePropertiesForm = ({ onSubmit, onCancel, values }: Props) => {
	const { t } = useTranslation();

	const { deviceId, deviceProperty } = values;

	const [lat, setLat] = useState<string>(deviceProperty?.lat?.toString() ?? '');
	const [latError, setLatError] = useState<boolean>(false);

	const [lng, setLng] = useState<string>(deviceProperty?.lng?.toString() ?? '');
	const [lngError, setLngError] = useState<boolean>(false);

	const [accuracy, setAccuracy] = useState<string>(deviceProperty?.accuracy?.toString() ?? '');
	const [accuracyError, setAccuracyError] = useState<boolean>(false);

	const handleSetLat = (event: ChangeEvent<HTMLInputElement>) => {
		setLat(event.target.value);
		setLatError(false);
	};

	const handleSetLng = (event: ChangeEvent<HTMLInputElement>) => {
		setLng(event.target.value);
		setLngError(false);
	};

	const handleSetAccuracy = (event: ChangeEvent<HTMLInputElement>) => {
		setAccuracy(event.target.value);
		setAccuracyError(false);
	};

	const handleSubmitForm = () => {
		let valid = true;

		let latNumber;

		if (lat && lat.length > 0) {
			latNumber = parseFloat(lat.replace(',', '.'));

			if (isNaN(latNumber)) {
				valid = false;
				setLatError(true);
			}
		}

		let lngNumber;

		if (lng && lng.length > 0) {
			lngNumber = parseFloat(lng.replace(',', '.'));

			if (isNaN(lngNumber)) {
				valid = false;
				setLngError(true);
			}
		}

		let accuracyNumber;

		if (accuracy && accuracy.length > 0) {
			accuracyNumber = parseInt(accuracy);

			if (isNaN(accuracyNumber)) {
				valid = false;
				setAccuracyError(true);
			}
		}

		if (valid) {
			onSubmit({
				deviceId: deviceId,
				deviceProperty: { lat: latNumber, lng: lngNumber, accuracy: accuracyNumber },
			});
		}
	};

	// const selectedDeviceType = deviceTypes.find((deviceType) => deviceType.id === values?.deviceTypeId);

	// console.log(selectedDeviceType);

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputField
					// autoFocus
					value={lat}
					error={latError}
					helperText={latError && t('validationErrors.notANumber')}
					label={t('lat')}
					name="lat"
					onChange={handleSetLat}
				/>
			</Grid>
			<Grid item>
				<InputField
					required={false}
					error={lngError}
					helperText={lngError && t('validationErrors.notANumber')}
					value={lng}
					label={t('lng')}
					name="lng"
					onChange={handleSetLng}
				/>
			</Grid>
			<Grid item>
				<InputField
					required={false}
					error={accuracyError}
					helperText={lngError && t('validationErrors.notANumber')}
					value={accuracy}
					label={t('accuracy')}
					name="accuracy"
					onChange={handleSetAccuracy}
				/>
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button onClick={handleSubmitForm} startIcon={<SaveIcon />} data-cy="btn-submit:userRoleForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:userRoleForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};
