import { subDays, subMonths } from 'date-fns';

export enum LanguagesEnum {
	EN = 'en',
	SK = 'sk',
	CZ = 'cz',
}

export enum STORAGE_KEYS {
	JWT_TOKEN = 'jwtToken',
	LANG = 'lang',
	TABLE_USERS = 'users',
	TABLE_DEVICES = 'devices',
	TABLE_INGESTION_ENDPOINTS = 'ingestionEndpoints',
}

export enum BREAKPOINT_KEYS {
	xs = 'xs',
	sm = 'sm',
	md = 'md',
	lg = 'lg',
	xl = 'xl',
}

export const BREAKPOINT_VALUES = {
	xs: 0,
	sm: 600,
	md: 960,
	lg: 1280,
	xl: 1920,
};

export const BREAKPOINTS = Object.entries(BREAKPOINT_VALUES).reduce(
	(acc: Record<string, string>, item: [string, number]) => {
		const key = item[0];
		const value = item[1];

		acc[key] = `(min-width:${value}px)`;

		return acc;
	},
	{},
);

export enum RequestHeaders {
	AUTH = 'Authorization',
}

export const NEW_LINES_REGEX = /\r?\n|\r/g;

// At least 8 characters long with at least one letter or special character and at least one number
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zÀ-ȕ\d±!@#$%^&*()_+\-=[\]{};:'"\\|,<.>/?`~]{8,}$/;

export const INPUT_DATE_MASK = 'dd.MM.yyyy';
export const INPUT_DATE_TIME_MASK = 'dd.MM.yyyy HH:mm:ss';

export const lastWeek = subDays(new Date(), 7);
export const lastTwoWeeks = subDays(new Date(), 14);
export const lastMonth = subMonths(new Date(), 1);
export const lastTwoMonths = subMonths(new Date(), 2);

export enum MapType {
	LOCATION = 'LOCATION',
	HEAT = 'HEAT',
	TRAIL = 'TRAIL',
}

// 1010 1010 0000 0001 1010 0101 0110

export enum PermissionsDotNet {
	UserRead = 0,
	UserWrite,
	UserRoleRead,
	UserRoleWrite,
	RoleRead,
	RoleWrite,
	TenantRead,
	TenantWrite,
	ProjectRead,
	ProjectWrite,
	DeviceRead,
	DeviceWrite,
	DeviceTypeRead,
	DeviceTypeWrite,
	ZoneRead,
	ZoneWrite,
	EndpointRead,
	EndpointWrite,
	ScriptRead,
	ScriptWrite,
	DeviceGroupRead,
	DeviceGroupWrite,
	WorkflowRead,
	WorkflowWrite,
	PlacementRead,
	PlacementWrite,
	ReportRead,
	ReportWrite,
	DevicePropertiesRead,
	DevicePropertiesWrite,
	IndoorMapRead,
	IndoorMapWrite,
	WarehouseRead,
	WarehouseWrite,
	PlacementDefinition,
	PlacementGroupRead,
	PlacementGroupWrite,
}
