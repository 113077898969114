import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { PrivateRoute } from './PrivateRoute';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { Login } from '../pages/Login/Login';
import { PageNotFound } from '../pages/PageNotFound/PageNotFound';
import { UserManagement } from '../pages/UserManagement/UserManagement';
import { Maps } from '../pages/Maps/Maps';
import { Devices } from '../pages/Devices/Devices';
import { Zones } from '../pages/Zones/Zones';
import { Workflows } from '../pages/Workflows/Workflows';
import { Notifications } from '../pages/Workflows/Notifications';
import { Billing } from '../pages/Billing/Billing';
import { IngestionEndpoints } from '../pages/IngestionEndpoints/IngestionEndpoints';
import { useTranslation } from 'react-i18next';
import { Settings } from '../pages/Settings/Settings';
import { UserDetail } from '../pages/UserDetail/UserDetail';
import { Groups } from '../pages/Groups/Groups';
import { GroupDetail } from '../pages/Groups/GroupDetail';
import { Projects } from '../pages/Projects/Projects';
import { WorkflowDetail } from '../pages/Workflows/WorkflowDetail';
import { Reports } from '../pages/Reports/Reports';
import { DeviceDetail } from '../pages/Devices/DeviceDetail';
import { IngestionScripts } from '../pages/IngestionScripts/IngestionScripts';
import { IngestionScriptDetail } from '../pages/IngestionScripts/IngestionScriptDetail';
import { Review } from '../pages/Review/Review';
import { ReportDetail } from '../pages/Review/ReportDetail';
import { UserRoles } from '../pages/UserRoles/UserRoles';
import { Logs } from '../pages/Logs/Logs';
import { DeviceTypes } from '../pages/DeviceTypes/DeviceTypes';
import { Tenants } from '../pages/Tenants/Tenants';
import { InDoorMaps } from '../pages/InDoorMaps/InDoorMaps';
import { Warehouse } from '../pages/Warehouse/Warehouse';
import { PlacementGroups } from '../pages/PlacementGroups/PlacementGroups';
import { PlacementGroupDetail } from '../pages/PlacementGroups/PlacementGroupDetail';
import { DevicePlacementManagement } from '../pages/DevicePlacementsManagement/DevicePlacementManagement';
import { Definitions } from '../pages/DevicePlacementDefinitions/Definitions';
import { VehicleWatch } from '../pages/VehicleWatch/VehicleWatch';
import { Drivers } from '../pages/VehicleWatch/Drivers';
import { VehicleWatchDetail } from '../pages/VehicleWatch/VehicleWatchDetail';

export enum RouteEnum {
	INDEX = '/',
	BILLING = '/billing',
	DASHBOARD = '/dashboard',
	DEVICES = '/devices',
	GROUPS = '/groups',
	PLACEMENT_GROUPS = '/placement-groups',
	INGESTION_ENDPOINTS = '/ingestion-endpoints',
	INGESTION_SCRIPTS = '/ingestion-scripts',
	LOGIN = '/login',
	MAPS = '/maps',
	NOTIFICATIONS = '/notifications',
	PLACEMENT_MANAGEMENT = '/placement-management',
	DEFINITIONS = '/definitions',
	PROJECTS = '/projects',
	REPORT = '/report',
	SETTINGS = '/settings',
	USER_MANAGEMENT = '/user-management',
	WORKFLOW_CREATE = '/create-workflow',
	WORKFLOWS = '/workflows',
	ZONES = '/zones',
	REVIEW = '/review',
	USER_ROLES = '/user-roles',
	LOGS = '/logs',
	DEVICE_TYPES = '/device-types',
	TENANTS = '/tenants',
	INDOOR_MAPS = '/indoor-maps',
	WAREHOUSE = '/warehouse',
	VEHICLE_WATCH = '/vehicle-watch',
}

type Props = {
	isLoggedIn: boolean;
};

export const Routes = ({ isLoggedIn }: Props) => {
	const { t } = useTranslation();

	return (
		<Switch>
			<Redirect from={RouteEnum.INDEX} to={RouteEnum.DASHBOARD} exact />
			<PrivateRoute
				component={Dashboard}
				exact
				path={RouteEnum.DASHBOARD}
				isLoggedIn={isLoggedIn}
				title={t('dashboard')}
			/>
			<PrivateRoute component={Maps} exact path={RouteEnum.MAPS} isLoggedIn={isLoggedIn} title={t('maps')} />
			<PrivateRoute
				component={Devices}
				exact
				path={RouteEnum.DEVICES}
				isLoggedIn={isLoggedIn}
				title={t('devices')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={DeviceDetail}
				exact
				path={`${RouteEnum.DEVICES}/:deviceSn`}
				isLoggedIn={isLoggedIn}
				title={t('device')}
				subTitle={t('management')}
			/>
			<PrivateRoute component={Zones} exact path={RouteEnum.ZONES} isLoggedIn={isLoggedIn} title={t('zones')} />
			<PrivateRoute
				component={Workflows}
				exact
				path={RouteEnum.WORKFLOWS}
				isLoggedIn={isLoggedIn}
				title={t('workflows')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={WorkflowDetail}
				exact
				path={`${RouteEnum.WORKFLOWS}/:workflowId`}
				isLoggedIn={isLoggedIn}
				title={t('workflows')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={WorkflowDetail}
				exact
				path={RouteEnum.WORKFLOW_CREATE}
				isLoggedIn={isLoggedIn}
				title={t('workflows')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={Billing}
				exact
				path={RouteEnum.BILLING}
				isLoggedIn={isLoggedIn}
				title={t('billing')}
			/>
			<PrivateRoute
				component={IngestionEndpoints}
				exact
				path={RouteEnum.INGESTION_ENDPOINTS}
				isLoggedIn={isLoggedIn}
				title={t('ingestionEndpoints')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={IngestionScripts}
				exact
				path={RouteEnum.INGESTION_SCRIPTS}
				isLoggedIn={isLoggedIn}
				title={t('ingestionScripts')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={IngestionScriptDetail}
				exact
				path={`${RouteEnum.INGESTION_SCRIPTS}/:scriptId`}
				isLoggedIn={isLoggedIn}
				title={t('ingestionScriptDetail')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={UserManagement}
				exact
				path={RouteEnum.USER_MANAGEMENT}
				isLoggedIn={isLoggedIn}
				title={t('users')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={UserDetail}
				exact
				path={`${RouteEnum.USER_MANAGEMENT}/:userId`}
				isLoggedIn={isLoggedIn}
				title={t('userDetail')}
			/>
			<PrivateRoute
				component={Settings}
				exact
				isLoggedIn={isLoggedIn}
				path={RouteEnum.SETTINGS}
				title={t('settings')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={Groups}
				exact
				isLoggedIn={isLoggedIn}
				path={RouteEnum.GROUPS}
				title={t('groups')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={PlacementGroups}
				exact
				isLoggedIn={isLoggedIn}
				path={RouteEnum.PLACEMENT_GROUPS}
				title={t('placementGroups')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={GroupDetail}
				exact
				isLoggedIn={isLoggedIn}
				path={`${RouteEnum.GROUPS}/:groupId`}
				title={t('group')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={PlacementGroupDetail}
				exact
				isLoggedIn={isLoggedIn}
				path={`${RouteEnum.PLACEMENT_GROUPS}/:placementGroupId`}
				title={t('placementGroup')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={Projects}
				exact
				path={RouteEnum.PROJECTS}
				isLoggedIn={isLoggedIn}
				title={t('projects')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={Reports}
				exact
				path={`${RouteEnum.REPORT}/:workflowId`}
				isLoggedIn={isLoggedIn}
				title={t('summary')}
			/>
			<PrivateRoute
				component={Notifications}
				exact
				path={`${RouteEnum.NOTIFICATIONS}/:workflowId`}
				isLoggedIn={isLoggedIn}
				title={t('notifications')}
			/>
			<PrivateRoute
				component={DevicePlacementManagement}
				exact
				path={RouteEnum.PLACEMENT_MANAGEMENT}
				isLoggedIn={isLoggedIn}
				title={t('placements')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={Definitions}
				exact
				path={RouteEnum.DEFINITIONS}
				isLoggedIn={isLoggedIn}
				title={t('definitions')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={Review}
				exact
				path={RouteEnum.REVIEW}
				isLoggedIn={isLoggedIn}
				title={t('reports')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={InDoorMaps}
				exact
				path={RouteEnum.INDOOR_MAPS}
				isLoggedIn={isLoggedIn}
				title={t('inDoorMaps')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={ReportDetail}
				exact
				path={`${RouteEnum.REVIEW}/:reportOrderId`}
				isLoggedIn={isLoggedIn}
				title={t('report')}
			/>
			<PrivateRoute
				component={UserRoles}
				exact
				path={RouteEnum.USER_ROLES}
				isLoggedIn={isLoggedIn}
				title={t('userRoles')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={Logs}
				exact
				path={RouteEnum.LOGS}
				isLoggedIn={isLoggedIn}
				title={t('logs')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={DeviceTypes}
				exact
				path={RouteEnum.DEVICE_TYPES}
				isLoggedIn={isLoggedIn}
				title={t('deviceTypes')}
				subTitle={t('management')}
			/>
			<PrivateRoute
				component={Tenants}
				exact
				path={RouteEnum.TENANTS}
				isLoggedIn={isLoggedIn}
				title={t('tenants')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={Warehouse}
				exact
				path={RouteEnum.WAREHOUSE}
				isLoggedIn={isLoggedIn}
				title={t('warehouse')}
				subTitle={t('configuration')}
			/>
			<PrivateRoute
				component={VehicleWatch}
				exact
				path={RouteEnum.VEHICLE_WATCH}
				isLoggedIn={isLoggedIn}
				title={t('vehicleWatch')}
				subTitle={t('carrierApp')}
			/>
			<PrivateRoute
				component={Drivers}
				exact
				path={`${RouteEnum.VEHICLE_WATCH}/drivers`}
				isLoggedIn={isLoggedIn}
				title={t('drivers')}
				subTitle={t('vehicleWatch')}
			/>
			<PrivateRoute
				component={VehicleWatchDetail}
				exact
				path={`${RouteEnum.VEHICLE_WATCH}/:placementId`}
				isLoggedIn={isLoggedIn}
				title={t('placement')}
				subTitle={t('vehicleWatch')}
			/>
			<Route path={RouteEnum.LOGIN} component={Login} exact />
			<Route path="*" component={PageNotFound} />
		</Switch>
	);
};
