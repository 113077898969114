import React, { useEffect, useState } from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowAction,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { TableRow, TableCell, CardHeader, Divider, Grid } from '@material-ui/core';
import { TableRowActionIcon } from '../../../components/Table/constants';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { Parameter, ParameterType, ReportOrder, ReviewDefinitionName } from '../review-types';
import { RouteEnum } from '../../../router/Routes';
import { useHistory } from 'react-router-dom';
import { fetchReportOrders, fetchReportOrdersNoProgressBar } from '../../../store/review/review-async-actions';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useDispatch } from 'react-redux';
import { LanguagesEnum, STORAGE_KEYS } from '../../../helpers/generalConstants';

type Props = {
	data: ReportOrder[];
	total: number;
	limit: number;
	offset: number;
	orderings?: TableColumnSort[];
	filters?: TableColumnFilter[];
	onClickScript?: (script: ReportOrder) => void;
	reviewDefinitionNames?: ReviewDefinitionName[];
};

export const ReportOrdersTable = ({
	data,
	total,
	limit,
	offset,
	orderings,
	filters,
	onClickScript,
	reviewDefinitionNames,
}: Props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();

	let lan = localStorage.getItem(STORAGE_KEYS.LANG) || LanguagesEnum.EN;

	if (lan === LanguagesEnum.CZ) {
		lan = 'cs';
	}
	// const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	// const canEditZones = permissions.includes(Permissions.EDIT_ZONES);

	// const nameOrder = orderings?.find((order) => order.column === 'name');
	const dateCreatedOrder = orderings?.find((order) => order.column === 'dateCreated');
	const statusOrder = orderings?.find((order) => order.column === 'status');

	// const nameFilter = filters?.find((filter) => filter.column === 'name');
	// const namespaceFilter = filters?.find((filter) => filter.column === 'namespace');

	const [refreshInterval] = useState(30000);

	const fetchMetrics = () => {
		// retrieve and then setData()
		console.log('REFRESH DATA');

		dispatch(fetchReportOrdersNoProgressBar());
	};

	useEffect(() => {
		if (refreshInterval && refreshInterval > 0) {
			const interval = setInterval(fetchMetrics, refreshInterval);

			return () => clearInterval(interval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshInterval]);

	const formatedDateTime = (dateTime: string, locale: string) => {
		try {
			return new Date(dateTime).toLocaleString(locale);
		} catch (exception) {
			return dateTime;
		}
	};

	const formatedDate = (dateTime: string, locale: string) => {
		try {
			return new Date(dateTime).toLocaleDateString(locale);
		} catch (exception) {
			return dateTime;
		}
	};

	const columns: TableHeadCell[] = [
		{
			id: 'name',
			label: t('name'),
			// filter: nameFilter && {
			// 	column: 'name',
			// 	operator: FilterOperators.contains,
			// 	value: nameFilter.value,
			// },
			// filterType: FilterType.String,
			// sort: nameOrder?.sortOrder && { column: 'name', sortOrder: nameOrder?.sortOrder },
			unorderable: true,
		},
		{
			id: 'reviewDefinitionId',
			label: t('reportType'),
			// filter: namespaceFilter && {
			// 	column: 'namespace',
			// 	operator: FilterOperators.contains,
			// 	value: namespaceFilter.value,
			// },
			// filterType: FilterType.String,
			// sort: namespaceOrder?.sortOrder && { column: 'namespace', sortOrder: namespaceOrder?.sortOrder },
			unorderable: true,
		},
		{
			id: 'dateCreated',
			label: t('dateCreated'),
			// filter: nameFilter && {
			// 	column: 'name',
			// 	operator: FilterOperators.contains,
			// 	value: nameFilter.value,
			// },
			// filterType: FilterType.String,
			sort: dateCreatedOrder?.sortOrder && { column: 'dateCreated', sortOrder: dateCreatedOrder?.sortOrder },
		},
		{
			id: 'groups',
			label: t('groups'),
			unorderable: true,
		},
		{
			id: 'parameters',
			label: t('parameters'),
			unorderable: true,
		},
		{
			id: 'status',
			label: t('reportStatus'),
			sort: statusOrder?.sortOrder && { column: 'status', sortOrder: statusOrder?.sortOrder },
		},
	];

	const rowActions = (reportOrder?: ReportOrder) => {
		const actions: TableRowAction<ReportOrder>[] = [];

		if (reportOrder?.standingOrder) {
			actions.push({
				id: 'btn-info:standing',
				icon: TableRowActionIcon.restore,
				tooltip: t('standingOrder'),
				onClick: (report: ReportOrder) => console.log('info'),
			});
		}

		if (reportOrder?.result) {
			actions.push({
				id: 'btn-detail:report',
				icon: TableRowActionIcon.detail,
				tooltip: t('detail'),
				onClick: (report: ReportOrder) => history.push(`${RouteEnum.REVIEW}/${report.id}`),
			});
		}

		return actions;
	};

	const formatedParameterValue = (parameter: Parameter) => {
		if (parameter.type === ParameterType.dateTime) {
			return formatedDateTime(parameter.value, lan);
		} else if (parameter.type === ParameterType.date) {
			return formatedDate(parameter.value, lan);
		}

		return parameter.value;
	};

	const renderTableRow = ({ onClickRow, row: reportOrder, renderActions }: TableRowRenderProps<ReportOrder>) => {
		return (
			<TableRow
				key={reportOrder.id}
				id={`row-${reportOrder.id}`}
				hover
				onClick={onClickScript ? () => onClickScript(reportOrder) : undefined}
			>
				<TableCell component="td" id={'reportOrder.name'} scope="row">
					{reportOrder.name}
				</TableCell>
				<TableCell component="td" id={'reportOrder.reviewDefinitionId'} scope="row">
					{
						reviewDefinitionNames?.find(
							(reviewDefinitionName) => reviewDefinitionName.id === reportOrder.reviewDefinitionId,
						)?.name
					}
				</TableCell>
				<TableCell component="td" id={'reportOrder.dateCreated'} scope="row">
					{formatedDateTime(reportOrder.dateCreated, lan)}
				</TableCell>
				<TableCell component="td" id={'reportOrder.placementGroups'} scope="row">
					{reportOrder.placementGroups && (
						<Grid container direction="column">
							{reportOrder.placementGroups.map((placementGroup) => {
								return (
									<Grid key={placementGroup.id} item>
										{placementGroup.name}
									</Grid>
								);
							})}
						</Grid>
					)}
				</TableCell>
				<TableCell component="td" id={'reportOrder.parameters'} scope="row">
					{
						<Grid container direction="column">
							{reportOrder.parameters.map((parameter) => {
								return (
									<Grid key={parameter.name} item>
										{`${parameter.name}: ${formatedParameterValue(parameter)}`}
									</Grid>
								);
							})}
						</Grid>
					}
				</TableCell>
				<TableCell component="td" id={'reportOrder.status'} scope="row">
					{t(`${reportOrder.status}Status`)}
				</TableCell>
				<TableCell padding="none" sortDirection={false} align="right">
					<TableActionsContainer>{renderActions(rowActions(reportOrder), reportOrder)}</TableActionsContainer>
				</TableCell>
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={
					<Grid container spacing={2} direction="row">
						<Grid item>
							<AssessmentIcon />
						</Grid>
						<Grid item>
							<>{t('reports')}</>
						</Grid>
					</Grid>
				}
			/>
			<Divider light />
			<Table
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				numberOfRows={total}
				fetchAction2={fetchReportOrders}
				actions={[]}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};
