import { createReducer } from 'typesafe-actions';
import {
	FETCH_ZONES_REQUEST,
	FETCH_ZONES_SUCCESS,
	FETCH_ZONES_ERROR,
	CREATE_ZONE_REQUEST,
	CREATE_ZONE_SUCCESS,
	CREATE_ZONE_ERROR,
	EDIT_ZONE_REQUEST,
	EDIT_ZONE_SUCCESS,
	EDIT_ZONE_ERROR,
	DELETE_ZONE_REQUEST,
	DELETE_ZONE_SUCCESS,
	DELETE_ZONE_ERROR,
	FETCH_ZONE_REQUEST,
	FETCH_ZONE_SUCCESS,
	FETCH_ZONE_ERROR,
	GET_ZONE_DETAIL,
	FETCH_ZONES_SUCCESS2,
} from './zones-constants';

import { ZonesActions, ZonesState } from './zones-types';
import { updateObjectInArray } from '../../helpers/array';
import { SortOrder } from '../../components/Table/constants';

const defaultOptions = {
	config: {
		limit: 10,
		offset: 0,
		order: SortOrder.Ascending,
		orderBy: 'id',
	},
	filters: {
		active: [],
		name: [],
		description: [],
	},
	orderings: undefined,
	filters2: undefined,
};

export const zonesInitialState: ZonesState = {
	...defaultOptions,
	data: [],
	total: 0,
};

export const zoneManagementReducer = createReducer<ZonesState, ZonesActions>(zonesInitialState, {
	[FETCH_ZONES_REQUEST]: (state) => state,
	[FETCH_ZONES_SUCCESS]: (state, action) => ({
		...state,
		data: action.payload.data.data,
		total: action.payload.data.total,
		config: { ...state.config, ...action.payload.options?.config },
		filters: { ...state.filters, ...action.payload.options?.filters },
	}),
	[FETCH_ZONES_SUCCESS2]: (state, action) => ({
		...state,
		data: action.payload.data ? action.payload.data.data : state.data,
		total: action.payload.data ? action.payload.data.total : state.total,
		config: {
			...state.config,
			limit: action.payload.options ? action.payload.options.limit : state.config.limit,
			offset: action.payload.options?.offset !== undefined ? action.payload.options.offset : state.config.offset,
		},
		filters: {},
		orderings: action.payload.options?.orderings,
		filters2: action.payload.options?.filtersAndConditions,
	}),
	[FETCH_ZONES_ERROR]: (state) => state,
	[EDIT_ZONE_REQUEST]: (state) => state,
	[EDIT_ZONE_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const zoneIndex = state.data.findIndex((zone) => zone.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, zoneIndex, state.data),
		};
	},
	[EDIT_ZONE_ERROR]: (state) => state,
	[CREATE_ZONE_REQUEST]: (state) => state,
	[CREATE_ZONE_SUCCESS]: (state) => state,
	[CREATE_ZONE_ERROR]: (state) => state,
	[DELETE_ZONE_REQUEST]: (state) => state,
	[DELETE_ZONE_SUCCESS]: (state, action) => {
		return {
			...state,
			data: state.data.filter((zone) => zone.id !== action.payload.id),
		};
	},
	[DELETE_ZONE_ERROR]: (state) => state,
	[FETCH_ZONE_REQUEST]: (state) => state,
	[FETCH_ZONE_SUCCESS]: (state, action) => {
		return {
			...state,
			zoneDetailData: action.payload.data,
		};
	},
	[FETCH_ZONE_ERROR]: (state) => state,
	[GET_ZONE_DETAIL]: (state, action) => {
		return {
			...state,
			zoneDetailData: action.payload.data,
		};
	},
});
