import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import storageLocal from 'redux-persist/lib/storage';
import { appReducer } from './app/app-reducer';
import { userReducer } from './user/user-reducer';
import { userManagementReducer } from './user-management/user-management-reducer';
import { telemetryReducer } from './telemetry/telemetry-reducer';
import { ingestionEndpointsReducer } from './ingestion-endpoints/ingestion-endpoints-reducer';
import { zoneManagementReducer } from './zones/zones-reducer';
import { projectsManagementReducer } from './projects/projects-reducer';
import { groupsReducer } from './groups/groups-reducer';
import { placementGroupsReducer } from './placement-groups/placement-groups-reducer';
import { devicesReducer } from './devices/devices-reducer';
import { workflowsReducer } from './workflows/workflows-reducer';
import { reportsReducer } from './reports/reports-reducer';
import { LOGOUT_SUCCESS } from './user/user-constants';
import { RootActions, RootState } from './types';
import { devicePlacementsReducer } from './device-placements/device-placements-reducer';
import { reviewReducer } from './review/review-reducer';
import { tenantsReducer } from './tenants/tenants-reducer';
import { inDoorMapsReducer } from './inDoorMaps/inDoorMaps-reducer';
import { warehouseReducer } from './warehouse/warehouse-reducer';
import { vehicleWatchReducer } from './vehicle-watch/vehicle-watch-reducer';

const telemetryPersistConfig = {
	key: 'telemetry',
	storage: storageSession,
};

const ingestionEndpointsPersistConfig = {
	key: 'ingestionEndpoints',
	storage: storageSession,
};

const userPersistConfig = {
	key: 'user',
	storage: storageLocal,
};

const usersPersistConfig = {
	key: 'users',
	storage: storageSession,
};

const zonesPersistConfig = {
	key: 'zones',
	storage: storageSession,
};

const projectsPersistConfig = {
	key: 'projects',
	storage: storageSession,
};

const groupsPersistConfig = {
	key: 'groups',
	storage: storageSession,
};

const placementGroupsPersistConfig = {
	key: 'placementGroups',
	storage: storageSession,
};

const devicesPersistConfig = {
	key: 'devices',
	storage: storageSession,
};

const workflowsPersistConfig = {
	key: 'workflows',
	storage: storageSession,
};

const reportsPersistConfig = {
	key: 'reports',
	storage: storageSession,
};

const devicePlacementsPersistConfig = {
	key: 'devicePlacements',
	storage: storageSession,
};

const reviewPersistConfig = {
	key: 'review',
	storage: storageSession,
};

const tenantsPersistConfig = {
	key: 'tenants',
	storage: storageSession,
};

const inDoorMapsPersistConfig = {
	key: 'inDoorMaps',
	storage: storageSession,
};

const warehousePersistConfig = {
	key: 'warehouse',
	storage: storageSession,
};

const vehicleWatchPersistConfig = {
	key: 'vehicleWatch',
	storage: storageSession,
};

const combinedReducers = combineReducers({
	app: appReducer,
	telemetry: persistReducer(telemetryPersistConfig, telemetryReducer),
	ingestionEndpoints: persistReducer(ingestionEndpointsPersistConfig, ingestionEndpointsReducer),
	user: persistReducer(userPersistConfig, userReducer),
	users: persistReducer(usersPersistConfig, userManagementReducer),
	zones: persistReducer(zonesPersistConfig, zoneManagementReducer),
	projects: persistReducer(projectsPersistConfig, projectsManagementReducer),
	groups: persistReducer(groupsPersistConfig, groupsReducer),
	placementGroups: persistReducer(placementGroupsPersistConfig, placementGroupsReducer),
	devices: persistReducer(devicesPersistConfig, devicesReducer),
	workflows: persistReducer(workflowsPersistConfig, workflowsReducer),
	reports: persistReducer(reportsPersistConfig, reportsReducer),
	devicePlacements: persistReducer(devicePlacementsPersistConfig, devicePlacementsReducer),
	review: persistReducer(reviewPersistConfig, reviewReducer),
	tenants: persistReducer(tenantsPersistConfig, tenantsReducer),
	inDoorMaps: persistReducer(inDoorMapsPersistConfig, inDoorMapsReducer),
	warehouse: persistReducer(warehousePersistConfig, warehouseReducer),
	vehicleWatch: persistReducer(vehicleWatchPersistConfig, vehicleWatchReducer),
});

export const rootReducer = (state: RootState, action: RootActions) => {
	if (action.type === LOGOUT_SUCCESS) {
		return combinedReducers(undefined, action);
	}

	return combinedReducers(state, action);
};
