import React, { ChangeEvent, useState, useEffect } from 'react';
import { Checkbox, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { InfoCard } from '../../components/InfoCard/InfoCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { BackButton } from '../../components/Button/BackButton';
import { useParams } from 'react-router-dom';
import { ValidateWorkflowResponse, Workflow } from './workflows-types';
import { InputField } from '../../components/Form/InputField';
import { useTranslation } from 'react-i18next';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SaveIcon from '@material-ui/icons/Save';
import { fetchRequest } from '../../services/helpers';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { Button } from '../../components/Button/Button';
import { Textarea } from '../../components/Form/Textarea';
import { formatWithPrettier } from '../../helpers/string';
import { setRedirectUrl } from '../../store/app/app-actions';
import { RouteEnum } from '../../router/Routes';
import { testWorkflowScript, validateWorkflowScript } from '../../services/workflows/workflows-service';
import { createWorkflow, editWorkflow } from '../../store/workflows/workflows-async-actions';
import { Autocomplete } from '@material-ui/lab';
import { getFilteredPlacementGroups } from '../../services/placement-groups/placement-groups-service';
import { GroupResponseData, GroupsResponseData } from '../PlacementGroups/placement-groups-types';
import { UserState } from '../../store/user/user-types';

require('codemirror/mode/dart/dart.js');

const useStyles = makeStyles(({ spacing }: Theme) => ({
	select: {
		'& .MuiFormLabel-root': {
			width: '90%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			paddingRight: spacing(2),

			'&.Mui-focused': {
				width: '100%',
				textOverflow: 'initial',
			},
		},
	},
}));

type RouteParams = {
	workflowId: string;
};

const defaultScript =
	'using Common.Contracts.Ingest;\nusing Common.Contracts.Workflow;\nusing System;\nusing System.Collections.Generic;\nusing System.Linq;\nusing System.Text;\nusing System.Threading.Tasks;\n\nnamespace Wokflow.Code\n{\n\tpublic class WorkflowFuctions\n\t{\n\t\tpublic IEnumerable\u003CNotification\u003E Check(TelemetryPayload payload,  WorkflowStoreData data)\n\t\t{\n\t\t\tif (payload.GetTelemetry(\u0022battery\u0022, 100) \u003C 20) yield return new Notification() {\n\t\t\t\tSeverity = 1, Message = \u0022Low battery warning !!\u0022\n\t\t\t};\n\t\t}\n\t}\n}';

export const WorkflowDetail = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { workflowId } = useParams<RouteParams>();

	const { project } = useSelector<RootState, UserState>((state) => state.user);

	const workflows = useSelector<RootState, Workflow[]>((state) => state.workflows.data);

	const workflow = workflows.find((workflow) => workflow.id === workflowId);

	const [name, setName] = useState(workflow?.name ?? '');

	const [description, setDescription] = useState(workflow?.description);

	const [isActive, setIsActive] = useState(workflow?.active ?? true);

	const [script, setScript] = useState(workflow?.situations?.sourceCode || defaultScript);

	const [validationState, setValidationState] = useState<'validate' | 'validating'>('validate');

	const [testState, setTestState] = useState<'testScript' | 'testingScript'>('testScript');

	const [validation, setValidation] = useState<ValidateWorkflowResponse>();

	const [nameEmpty, setNameEmpty] = useState<boolean>(false);

	const [testDataEmpty, setTestDataEmpty] = useState<boolean>(false);

	const [testResult, setTestResult] = useState<ValidateWorkflowResponse>();

	const [testData, setTestData] = useState<string>();

	const [allPlacementGroups, setAllPlacementGruops] = useState<GroupResponseData[]>([]);

	const [selectedPlacementGroupIds, setSelectedPlacementGruopIds] = useState(workflow?.placementGroupIds ?? []);

	// const [logs, setLogs] = useState<LogData[]>();

	const testResultJson = JSON.stringify(testResult?.result);

	let formattedTestResultJson;

	try {
		formattedTestResultJson = testResultJson && formatWithPrettier(testResultJson);
	} catch (exception) {
		formattedTestResultJson = testResultJson;
	}

	useEffect(() => {
		const request = getFilteredPlacementGroups({
			limit: 100,
			offset: 0,
			filtersAndConditions: [],
			orderings: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<GroupsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				if (data) {
					setAllPlacementGruops(data.data);
				}
			}
		})();
	}, [dispatch, project]);

	const handleSelectPlacementGroup = (event: any, newValue: GroupResponseData[]) => {
		setSelectedPlacementGruopIds(newValue.map((value) => value.id));
	};

	const handleNameChange = (event: any) => {
		setName(event.target.value);
		setNameEmpty(false);
	};

	const handleDescriptionChange = (event: any) => {
		setDescription(event.target.value);
	};

	const handleScriptChange = (value: string) => {
		setScript(value);
	};

	const handleTestDataChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTestData(event.currentTarget.value);
		setTestDataEmpty(false);
	};

	const handleSubmitForm = () => {
		let valid = true;

		if (name === '') {
			setNameEmpty(true);
			valid = false;
		}

		if (valid) {
			if (workflow?.id === undefined || workflow.id === '') {
				dispatch(
					createWorkflow({
						name: name,
						description: description,
						active: isActive,
						placementGroupIds: selectedPlacementGroupIds,
						situations: {
							sourceCode: script,
						},
					}),
				);
			} else {
				dispatch(
					editWorkflow({
						id: workflow.id,
						name: name,
						description: description,
						active: isActive,
						placementGroupIds: selectedPlacementGroupIds,
						situations: {
							sourceCode: script,
						},
					}),
				);
			}

			dispatch(setRedirectUrl(RouteEnum.WORKFLOWS));
		}
	};

	const handleValidateForm = () => {
		const request = validateWorkflowScript({
			name: name,
			description: description,
			active: isActive,
			placementGroupIds: selectedPlacementGroupIds,
			situations: {
				sourceCode: script,
			},
		});

		setValidationState('validating');

		(async () => {
			const { data, error } = await fetchRequest<ValidateWorkflowResponse>(request);

			setValidationState('validate');

			if (error) {
				// TODO show error
			} else {
				if (data) {
					setValidation(data);
				}
			}
		})();
	};

	const handleTestForm = () => {
		if (testData === undefined || testData === '') {
			setTestDataEmpty(true);
		} else {
			const request = testWorkflowScript({
				name: name,
				description: description,
				active: isActive,
				placementGroupIds: selectedPlacementGroupIds,
				situations: {
					sourceCode: script,
				},
				testData: testData,
			});

			setTestState('testingScript');

			(async () => {
				const { data, error } = await fetchRequest<ValidateWorkflowResponse>(request);

				setTestState('testScript');

				if (error) {
					// TODO show error
				} else {
					if (data) {
						setTestResult(data);
					}
				}
			})();
		}
	};

	function getSelectedPlacementGroups() {
		return allPlacementGroups.filter((placementGroup) =>
			selectedPlacementGroupIds.find(
				(selectedPlacementGroupId) => placementGroup.id === selectedPlacementGroupId,
			),
		);
	}

	return (
		<Grid container spacing={2} direction="column">
			<Grid item xs={12}>
				<BackButton />
			</Grid>
			<Grid item sm={12} lg={10} xl={10}>
				<InfoCard>
					<>
						<Grid container spacing={2} direction="column">
							<Grid item>
								<InputField
									autoFocus
									error={nameEmpty}
									helperText={nameEmpty && t('validationErrors.required')}
									label={t('name')}
									name="name"
									onChange={handleNameChange}
									defaultValue={name}
								/>
							</Grid>
							<Grid item>
								<InputField
									autoFocus
									label={t('description')}
									name="description"
									onChange={handleDescriptionChange}
									defaultValue={description}
								/>
							</Grid>
							<Grid item>
								<Grid container alignItems="center" direction="row">
									<Grid item>
										<Checkbox
											color="primary"
											// size="small"
											// indeterminate={numSelectedRows > 0 && numSelectedRows < rowCount}
											checked={isActive}
											onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
												setIsActive(checked)
											}
										/>
									</Grid>
									<Grid item>
										<Typography variant="inherit">
											{isActive ? t('active') : t('inactive')}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Autocomplete
									className={classes.select}
									color="primary"
									multiple
									options={allPlacementGroups}
									getOptionLabel={(option) => option.name}
									value={getSelectedPlacementGroups()}
									filterSelectedOptions
									disableCloseOnSelect
									fullWidth
									renderInput={(params) => (
										<TextField
											{...params}
											label={t('selectPlacementGroup')}
											// placeholder="Favorites"
											// error={permissionsError}
											// helperText={permissionsError && t('validationErrors.required')}
											variant="outlined"
										/>
									)}
									onChange={handleSelectPlacementGroup}
								/>
							</Grid>
							<Grid item>
								<CodeMirror
									autoCursor={false}
									value={script}
									options={{
										mode: 'dart',
										theme: 'material',
										lineNumbers: true,
									}}
									onChange={(editor, data, value) => {
										handleScriptChange(value);
									}}
								/>
							</Grid>
						</Grid>
						<Button
							disabled={validationState !== 'validate'}
							startIcon={<PlaylistAddCheckIcon />}
							onClick={() => handleValidateForm()}
							type="button"
							variant="text"
							data-cy="btn-submit-validate:scriptForm"
						>
							{t(validationState)}
						</Button>
						<TextField
							fullWidth
							multiline
							disabled
							style={{ pointerEvents: 'none' }}
							name="test data"
							value={validation?.message}
							type={'text'}
							variant="standard"
						/>
						<Textarea
							code
							name="test data"
							label="test data"
							error={testDataEmpty}
							helperText={testDataEmpty && t('validationErrors.required')}
							onChange={handleTestDataChange}
						/>
						<Button
							disabled={testState !== 'testScript'}
							startIcon={<PlaylistAddCheckIcon />}
							onClick={() => handleTestForm()}
							type="button"
							variant="text"
							data-cy="btn-submit-validate:scriptForm"
						>
							{t(testState)}
						</Button>
						<TextField
							fullWidth
							multiline
							disabled
							style={{ pointerEvents: 'none' }}
							name="test data"
							value={testResult?.message}
							type={'text'}
							variant="standard"
						/>
						<TextField
							fullWidth
							multiline
							disabled
							style={{ pointerEvents: 'none' }}
							name="test result"
							value={formattedTestResultJson}
							type={'text'}
							variant="standard"
						/>
					</>
				</InfoCard>
			</Grid>
			<Grid item>
				<Button startIcon={<SaveIcon />} onClick={() => handleSubmitForm()} data-cy="btn-submit:scriptForm">
					{t('save')}
				</Button>
			</Grid>
		</Grid>
	);
};
