import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { MapContainer } from '../../../components/Maps/MapContainer';
import { DeviceResponseData2, DevicesResponseData2 } from '../devices-types';
import { ArrowForwardRounded, LocalOfferRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Card,
	CardHeader,
	Checkbox,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from '@material-ui/core';
import { BatteryIcon } from './BatteryIcon';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { RouteEnum } from '../../../router/Routes';
import { MapPopup } from '../../Maps/components/MapPopup';
import { format } from 'date-fns';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { SPACING } from '../../../theme';
import { CONTENT_PADDING_SM_UP } from '../../../components/Layout/Content';
import { DeviceDetailZones } from './DeviceDetailZones';
import { Zone, ZoneResponseData, ZonesResponseData } from '../../Zones/zones-types';
import { getBoundsFromMultipleDevices, getBoundsFromMultipleZones, toLatLngExpression } from '../../Zones/helpers';
import { fromJSONToObject } from '../../../helpers/object';
import { LatLngLiteral, LatLngTuple } from 'leaflet';
import { useZonesBounds } from '../../Zones/hooks';
import { MapMarker, MarkerIcon } from '../../Maps/components/MapMarker';
import { Sidebar, Tab } from 'react-leaflet-sidebarv2';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import RouterIcon from '@material-ui/icons/Router';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import { fetchRequest } from '../../../services/helpers';
import { getFilteredDevices } from '../../../services/devices/devices-service';
import { FilterOperators, SortOrder } from '../../../components/Table/constants';
import { getFilteredZones2 } from '../../../services/zones/zones-service';
import { InputField } from '../../../components/Form/InputField';
import { fetchPlacements } from '../../../services/device-placements/device-placements-service';
import {
	DevicePlacement,
	DevicePlacementsResponseData,
} from '../../DevicePlacementsManagement/device-placements-types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { setMapFavourites } from '../../../store/user/user-actions';
import { fetchInDoorMaps } from '../../../store/inDoorMaps/inDoorMaps-async-actions';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import { GeoJSON } from 'react-leaflet';
import { tryFeatureCollection } from 'pure-geojson-validation';
import { FeatureCollection } from 'geojson';
import { getBoundsFromGeoJson } from '../../InDoorMaps/helpers';
import { UUID } from '../../../types';
import { InDoorMapsState } from '../../../store/inDoorMaps/inDoorMaps-types';
import { onEachFeature } from '../../../components/Maps/helpers';
import { LocalizationMethod } from '../constants';

export type Favourite = {
	id: string;
	type: 'placement' | 'device' | 'zone';
	device?: DeviceResponseData2;
	zone?: ZoneResponseData;
	title?: string;
	subtitle?: string;
	count: number;
};

type InDoorMapItem = {
	id: UUID;
	name: string;
	geojson?: FeatureCollection;
};

type InDoorMapHide = {
	id: UUID;
	hide: boolean;
};

export const DevicesAndZonesMap = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const { mapBounds, setMapBounds } = useZonesBounds(undefined);

	const [sidebarColabsed, setSidebarColabsed] = useState(true);
	const [sidebarSelected, setSidebarSelected] = useState('home');

	const selectedProjectId = useSelector<RootState, string | undefined>((state) => state.user.project?.id);

	const [selectedItem, setSelectedItem] = useState<string>();

	const [placements, setPlacements] = useState<DevicePlacement[]>([]);

	const [devices, setDevices] = useState<DeviceResponseData2[]>([]);

	const [zones, setZones] = useState<ZoneResponseData[]>();

	const [placementsSearchKey, setPlacementsSearchKey] = useState<string>();
	const [devicesSearchKey, setDevicesSearchKey] = useState<string>();
	const [zonesSearchKey, setZonesSearchKey] = useState<string>();
	const [inDoorMapsSearchKey, setInDoorMapsSearchKey] = useState<string>();

	const { data: inDoorMapsData } = useSelector<RootState, InDoorMapsState>((state) => state.inDoorMaps);

	const [inDoorMaps, setInDoorMaps] = useState<InDoorMapItem[]>([]);

	const [inDoorMapsHide, setIndoorMapsHide] = useState<InDoorMapHide[]>([]);

	const [geojsonsKey, setGeojsonsKey] = useState<string>('');

	const [showStatic, setShowStatic] = useState(true);

	const favourites = useSelector<RootState, Favourite[]>((state) => state.user.favourites);

	const stageCanvasRef = useRef(null);

	function mergeMapBounds(bounds?: LatLngTuple[]) {
		if (bounds === undefined || bounds.length !== 2) {
			return;
		}

		if (mapBounds !== undefined && mapBounds.length === 2) {
			setMapBounds([
				[Math.min(bounds[0][0], mapBounds[0][0]), Math.min(bounds[0][1], mapBounds[0][1])],
				[Math.max(bounds[1][0], mapBounds[1][0]), Math.max(bounds[1][1], mapBounds[1][1])],
			] as LatLngTuple[]);
		} else {
			setMapBounds(bounds);
		}
	}

	useEffect(() => {
		setInDoorMaps(
			inDoorMapsData.map((inDoorMap) => {
				let geojson: FeatureCollection | undefined = undefined;

				try {
					const geoj: unknown = JSON.parse(inDoorMap.layers[0].image); // TODO make for all floors

					const unsafeFC = tryFeatureCollection(geoj as string);

					geojson = unsafeFC;
				} catch (exception) {}

				return { id: inDoorMap.id, name: inDoorMap.name, geojson: geojson } as InDoorMapItem;
			}),
		);
	}, [inDoorMapsData]);

	useEffect(() => {
		setIndoorMapsHide(inDoorMaps.map((inDoorMap) => ({ id: inDoorMap.id, hide: inDoorMap.geojson === undefined })));
	}, [inDoorMaps]);

	useEffect(() => {
		setGeojsonsKey(getGeojsonKey(inDoorMapsHide));
	}, [inDoorMapsHide]);

	/**
	 * Fetching all placements
	 */
	useEffect(() => {
		const filters = [];

		if (selectedProjectId) {
			filters.push({
				column: 'projects',
				operator: FilterOperators.injoin,
				value: selectedProjectId,
			});
		}

		const request = fetchPlacements({
			limit: 1000,
			offset: 0,
			orderings: [{ column: 'dateUpdated', sortOrder: SortOrder.Descending }],
			filtersAndConditions: filters,
		});

		(async () => {
			// setLoading(true);

			const { data, error } = await fetchRequest<DevicePlacementsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				// console.log(data);
				if (data?.data) {
					setPlacements(data.data);
				}
			}

			// setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProjectId]);

	/**
	 * Fetching all devices
	 */
	useEffect(() => {
		const filterBody = {
			offset: 0,
			limit: 2000,
			orderings: [{ column: 'dateUpdated', sortOrder: SortOrder.Descending }],
			filtersAndConditions: [
				{
					column: 'includeData',
					operator: FilterOperators.equals,
					value: 'true',
				},
			],
		};

		const request = getFilteredDevices(filterBody);

		(async () => {
			const { data, error } = await fetchRequest<DevicesResponseData2>(request);

			if (error) {
				console.log(error);
				// TODO show error
			} else {
				// console.log(data);
				if (data?.data) {
					if (data.data.length > 0) {
						console.log(`! ${mapBounds}`);

						const bounds = getBoundsFromMultipleDevices(data.data);

						mergeMapBounds(bounds);

						pairPlacementsToDevices();
					}

					setDevices(data.data);
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		pairPlacementsToDevices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [placements]);

	function pairPlacementsToDevices() {
		if (placements && placements.length > 0) {
			if (devices && devices.length > 0) {
				placements.forEach((placement) => {
					const attached = placement.placementRelations?.find((relation) => relation.detached === null);

					if (attached) {
						const attachedDevice = devices.find((device) => device.id === attached.deviceId);

						if (attachedDevice) {
							attachedDevice.placementRelations = placement.placementRelations;
						}
					}
				});

				setDevices([...devices]);
			}
		}
	}

	/**
	 * Fetching all zones
	 */
	useEffect(() => {
		if (!zones) {
			const request = getFilteredZones2({
				// Should be enough to get all zones, even for super admin
				limit: 1000,
				offset: 0,
				orderings: [],
				filtersAndConditions: [],
			});

			(async () => {
				const { data, error } = await fetchRequest<ZonesResponseData>(request);

				if (error) {
					console.log(error);
					// TODO show error
				} else {
					if (data) {
						setZones(data.data);

						if (data.data && data.data.length > 0) {
							const bounds = getBoundsFromMultipleZones(data.data);

							mergeMapBounds(bounds);
						}
					}
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Fetching all inDoorMaps
	 */
	useEffect(() => {
		if (selectedProjectId) {
			dispatch(
				fetchInDoorMaps({
					// Should be enough to get all zones, even for super admin
					limit: 1000,
					offset: 0,
					orderings: [],
					filtersAndConditions: [],
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		/// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, selectedProjectId]);

	function getGeojsonKey(inDoorMap: InDoorMapHide[]) {
		let key = '';

		inDoorMap.forEach((hide) => {
			if (hide.hide === false) {
				key = `${key}-${hide.id}`;
			}
		});

		return key;
	}

	const handleSetFavourite = (
		id: string,
		type: Favourite['type'],
		title?: string,
		subtitle?: string,
		device?: DeviceResponseData2,
		zone?: ZoneResponseData,
	) => {
		const favourite = favourites.find((favourite) => favourite.id === id);

		if (favourite) {
			favourite.count = favourite.count + 1;
			dispatch(setMapFavourites([...favourites]));
		} else {
			dispatch(
				setMapFavourites([
					...favourites,
					{ id: id, type: type, device: device, zone: zone, title: title, subtitle: subtitle, count: 0 },
				]),
			);
		}
	};

	const handleSetSelectedItem = (id: string, type: Favourite['type'], source: string, writeToFovourite: boolean) => {
		setSelectedItem(id);

		if (id && writeToFovourite) {
			switch (type) {
				case 'device':
					const device = devices?.find((device) => device.id === id);

					handleSetFavourite(id, type, device?.sn, device?.deviceType?.name, device, undefined);

					break;
				case 'placement':
					const placement = placements?.find((placement) => placement.id === id);

					handleSetFavourite(
						id,
						type,
						placement?.placementDescription?.title1,
						placement?.placementDescription?.subTitle1,
						// placement?.attachedDevice,
					);

					break;
				case 'zone':
					const zone = zones?.find((zone) => zone.id === id);

					handleSetFavourite(id, type, zone?.name, zone?.description, undefined, zone);

					break;
			}
		}
	};

	const handleUnelectedItem = () => {
		setSelectedItem(undefined);
	};

	const handleRedirectToDeviceDetail = (deviceSn: string) => {
		history.push(`${RouteEnum.DEVICES}/${deviceSn}`);
	};

	const handleClickDevice = (device: DeviceResponseData2) => {
		if (device.data?.lat !== undefined && device.data?.lng !== undefined) {
			setMapBounds([[device.data.lat, device.data.lng]]);
			handleSetSelectedItem(device.id, 'device', 'handleClickDevice', false);
		}
	};

	const handleClickZone = (zone: Zone) => {
		const coords = fromJSONToObject<LatLngLiteral[]>(zone.polygon);

		if (coords) {
			const bounds = toLatLngExpression(coords);

			setMapBounds(bounds);

			handleSetSelectedItem(zone.id, 'zone', 'handleClickZone', false);
		}
	};

	function handleClickInDoorMap(inDoorMap: InDoorMapItem) {
		inDoorMap.geojson && setMapBounds(getBoundsFromGeoJson(inDoorMap.geojson));
	}

	const onClose = () => {
		setSidebarColabsed(true);
	};

	const onOpen = (id: string) => {
		setSidebarColabsed(false);
		setSidebarSelected(id);
	};

	function getInDoorMapGeojsons() {
		const geojsons: FeatureCollection[] = [];

		inDoorMaps.forEach((inDoorMap) => {
			const hide = inDoorMapsHide.find((hide) => hide.id === inDoorMap.id)?.hide ?? false;

			return hide === false && inDoorMap.geojson && geojsons.push(inDoorMap.geojson);
		});

		return geojsons;
	}

	function onHideIndoorMap(inDoorMap: InDoorMapItem, checked: boolean) {
		let key = '';

		const hides = inDoorMapsHide.map((hide) => {
			if (hide.hide === false) {
				key = `${key}-${hide.id}`;
			}

			if (hide.id === inDoorMap.id) {
				return { id: hide.id, hide: checked === false };
			} else {
				return hide;
			}
		});

		setIndoorMapsHide(hides);
	}

	const drawInDoorMapsTab = () => {
		return (
			<>
				<InputField
					autoFocus
					label={'search'}
					margin="normal"
					name={'search maps'}
					onChange={(event: ChangeEvent<HTMLInputElement>) => setInDoorMapsSearchKey(event.target.value)}
				/>
				<List
					id="main-menu"
					style={{
						height: `calc(100vh - ${CONTENT_PADDING_SM_UP * SPACING * 2 + 210}px)`,
						overflow: 'auto',
					}}
				>
					{inDoorMaps
						?.filter((inDoorMap) => {
							const key = inDoorMapsSearchKey?.toLowerCase();

							return !key || inDoorMap.name.toLowerCase().includes(key);
						})
						.map((inDoorMap) => (
							<ListItem
								key={inDoorMap.id}
								button
								onClick={() => handleClickInDoorMap(inDoorMap)}
								// selected={inDoorMap.id === geo?.key}
								disabled={inDoorMap.geojson === undefined}
							>
								<ListItemIcon>
									<Checkbox
										// disabled={!canEditDevices}
										checked={
											inDoorMapsHide.find((hide) => inDoorMap.id === hide.id)?.hide === false
										}
										color="primary"
										inputProps={{ 'aria-labelledby': inDoorMap.name }}
										name={`id-${inDoorMap.id}`}
										// tabIndex={-1}
										onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
											onHideIndoorMap(inDoorMap, checked)
										}
									/>
								</ListItemIcon>
								<ListItemText primary={inDoorMap.name} />
								{/* <ListItemIcon>
									{inDoorMap.active ? (
										<CheckRounded htmlColor={COLOR_THEME.success} data-cy="check:active" />
									) : (
										<ClearRounded htmlColor={COLOR_THEME.error} data-cy="check:inactive" />
									)}
								</ListItemIcon> */}
							</ListItem>
						))}
				</List>
			</>
		);
	};

	return (
		<MapContainer
			bounds={mapBounds}
			height={`calc(100vh - ${CONTENT_PADDING_SM_UP * SPACING * 2}px)`}
			boxShadow
			sidebar={
				<Sidebar
					id="sidebar"
					collapsed={sidebarColabsed}
					selected={sidebarSelected}
					onOpen={(id) => onOpen(id)}
					onClose={onClose}
					position={'right'}
					closeIcon={<ArrowForwardIosIcon />}
					ref={stageCanvasRef}
				>
					<Tab id="home" header="Home" icon={<HomeIcon />}>
						<Box height={SPACING * 3} />
						<Card>
							<CardHeader
								title={
									<Typography component="h2" variant="h6">
										{'Tenant info'}
									</Typography>
								}
							/>
							<p>Tenant info (TODO)</p>
						</Card>
						<Box height={SPACING * 3} />
						<Card>
							<CardHeader
								title={
									<Typography component="h2" variant="h6">
										{t('favourites')}
									</Typography>
								}
							/>
							{favourites
								.sort((favourite1, favourite2) => favourite2.count - favourite1.count)
								.slice(0, 5)
								.map((favourite) => (
									<ListItem
										key={favourite.id}
										button
										onClick={() => {
											favourite.device && handleClickDevice(favourite.device);
											favourite.zone && handleClickZone(favourite.zone);
										}}
										selected={favourite.id === selectedItem}
									>
										<ListItemIcon>
											{favourite.type === 'placement' && <LocalOfferRounded />}
											{favourite.type === 'device' && <RouterIcon />}
											{favourite.type === 'zone' && <BubbleChartIcon />}
										</ListItemIcon>
										<ListItemText primary={favourite.title} secondary={favourite.subtitle} />
									</ListItem>
								))}
						</Card>
					</Tab>
					<Tab id="placements" header={t('placements')} icon={<LocalOfferRounded />}>
						<InputField
							autoFocus
							label={'search'}
							margin="normal"
							name={'search placements'}
							onChange={(event: ChangeEvent<HTMLInputElement>) =>
								setPlacementsSearchKey(event.target.value)
							}
						/>
						<List
							id="main-menu"
							style={{
								height: `calc(100vh - ${CONTENT_PADDING_SM_UP * SPACING * 2 + 170}px)`,
								overflow: 'auto',
							}}
						>
							{placements
								?.filter((placement) => {
									const key = placementsSearchKey?.toLowerCase();

									return (
										!key ||
										placement.placementDescription?.title1.toLowerCase().includes(key) ||
										placement.placementDescription?.subTitle1.toLowerCase().includes(key)
									);
								})
								.map((placement) => {
									const attachedDeviceId = placement.placementRelations.find(
										(relation) => relation.detached === null,
									)?.deviceId;

									const attachedDevice = devices.find((device) => device.id === attachedDeviceId);

									return (
										<ListItem
											disabled={
												attachedDevice === undefined ||
												attachedDevice.data === undefined ||
												attachedDevice.data === null
											}
											key={placement.id}
											button
											onClick={() => {
												attachedDevice && handleClickDevice(attachedDevice);
											}}
											selected={attachedDevice && attachedDeviceId === selectedItem}
										>
											<ListItemIcon>
												<LocalOfferRounded />
											</ListItemIcon>
											<ListItemText
												primary={placement.placementDescription?.title1}
												secondary={placement.placementDescription?.subTitle1}
											/>
											{attachedDevice && (
												<ListItemSecondaryAction>
													<IconButton
														edge="end"
														onClick={() =>
															attachedDevice &&
															handleRedirectToDeviceDetail(attachedDevice.sn)
														}
													>
														<RouterIcon />
													</IconButton>
												</ListItemSecondaryAction>
											)}
										</ListItem>
									);
								})}
						</List>
					</Tab>
					<Tab id="devices" header={t('devices')} icon={<RouterIcon />}>
						<InputField
							autoFocus
							label={'search'}
							margin="normal"
							name={'search devices'}
							onChange={(event: ChangeEvent<HTMLInputElement>) => setDevicesSearchKey(event.target.value)}
						/>
						<Grid container alignContent="center" alignItems="center" direction="row">
							<Grid item>
								<Checkbox
									color="primary"
									// size="small"
									// indeterminate={numSelectedRows > 0 && numSelectedRows < rowCount}
									checked={showStatic}
									onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
										setShowStatic(checked)
									}
								/>
							</Grid>
							<Grid item>
								<Typography variant="body2" color="inherit">
									{t('showStatic')}
								</Typography>
							</Grid>
						</Grid>
						<List
							id="main-menu"
							style={{
								height: `calc(100vh - ${CONTENT_PADDING_SM_UP * SPACING * 2 + 200}px)`,
								overflow: 'auto',
							}}
						>
							{devices
								?.filter((deviceResponseData) => {
									const key = devicesSearchKey?.toLowerCase();

									return (
										(!key ||
											deviceResponseData.sn.toLowerCase().includes(key) ||
											deviceResponseData.deviceType?.name.toLowerCase().includes(key)) &&
										(showStatic
											? true
											: deviceResponseData.data?.location_update_details?.toLowerCase() !==
											  LocalizationMethod.STATIC)
									);
								})
								.map((deviceResponseData) => (
									<ListItem
										disabled={
											deviceResponseData.data === undefined ||
											deviceResponseData.data === null ||
											deviceResponseData.data.lat === undefined ||
											deviceResponseData.data.lng === undefined ||
											(deviceResponseData.data.lat === 0 && deviceResponseData.data.lng === 0)
										}
										key={deviceResponseData.id}
										button
										onClick={() => handleClickDevice(deviceResponseData)}
										selected={deviceResponseData.id === selectedItem}
									>
										<ListItemIcon>
											<RouterIcon />
										</ListItemIcon>
										<ListItemText
											primary={deviceResponseData.sn}
											secondary={deviceResponseData.deviceType?.name}
										/>
										{/* {deviceResponseData.title && (
											<ListItemIcon>
												<LocalOfferRounded />
											</ListItemIcon>
										)} */}
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												onClick={() => handleRedirectToDeviceDetail(deviceResponseData.sn)}
											>
												<InfoOutlinedIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
						</List>
					</Tab>
					<Tab id="zones" header={t('zones')} icon={<BubbleChartIcon />}>
						<InputField
							autoFocus
							label={'search'}
							margin="normal"
							name={'search zones'}
							onChange={(event: ChangeEvent<HTMLInputElement>) => setZonesSearchKey(event.target.value)}
						/>
						<List
							id="main-menu"
							style={{
								height: `calc(100vh - ${CONTENT_PADDING_SM_UP * SPACING * 2 + 170}px)`,
								overflow: 'auto',
							}}
						>
							{zones
								?.filter((zoneResponseData) => {
									return (
										!zonesSearchKey ||
										zoneResponseData.name.toLowerCase().includes(zonesSearchKey.toLowerCase())
									);
								})
								.map((zoneResponseData) => (
									<ListItem
										key={zoneResponseData.id}
										button
										onClick={() => handleClickZone(zoneResponseData)}
										selected={zoneResponseData.id === selectedItem}
									>
										<ListItemIcon>
											<BubbleChartIcon />
										</ListItemIcon>
										<ListItemText
											primary={zoneResponseData.name}
											secondary={zoneResponseData.description}
										/>
									</ListItem>
								))}
						</List>
					</Tab>
					<Tab id="inDoorMaps" header={t('inDoorMaps')} icon={<BorderClearIcon />}>
						{drawInDoorMapsTab()}
					</Tab>
					<Tab id="settings" header="Settings" icon={<SettingsIcon />} anchor="bottom">
						<p>Settings dialogue.</p>
					</Tab>
				</Sidebar>
			}
		>
			<GeoJSON key={geojsonsKey} data={getInDoorMapGeojsons()} onEachFeature={onEachFeature} />

			{zones && (
				<DeviceDetailZones
					onClick={handleClickZone}
					zones={zones}
					selectedZoneId={selectedItem}
					onSelected={(id: string) => handleSetSelectedItem(id, 'zone', 'zone onpopup', true)}
				/>
			)}

			{devices?.map(({ id, sn, deviceType, data, placementRelations }) => {
				const { lat, lng, accuracy, battery, temperature, time, location_update_details } = data || {};

				const isStatic = location_update_details?.toLowerCase() === LocalizationMethod.STATIC;

				if (lat && lng && (showStatic ? true : isStatic === false)) {
					const attachedPlacementRelation = placementRelations?.find(
						(relation) => relation.detached === null,
					);

					const attachedPlacementId = attachedPlacementRelation?.placementId;

					const attachedPlacement = placements.find((placement) => placement.id === attachedPlacementId);

					let type: MarkerIcon;

					if (attachedPlacementId) {
						if (isStatic) {
							type = 'deviceStatic';
						} else {
							type = 'device';
						}
					} else {
						if (isStatic) {
							type = 'deviceNoPlacementStatic';
						} else {
							type = 'deviceNoPlacement';
						}
					}

					return (
						<MapMarker
							type={type}
							key={sn}
							position={[lat, lng]}
							popupOpend={id === selectedItem}
							onpopupopen={() => handleSetSelectedItem(id, 'device', 'device onpopup', true)}
							onpopupclose={handleUnelectedItem}
						>
							{attachedPlacementId ? (
								<MapPopup
									title={attachedPlacement?.placementDescription?.title1 ?? 'No name'}
									action={
										<Button
											onClick={() => handleRedirectToDeviceDetail(sn)}
											endIcon={<ArrowForwardRounded />}
											variant="text"
											size="small"
										>
											{t('deviceDetail')}
										</Button>
									}
								>
									<List dense disablePadding>
										<ListItem disableGutters>
											{attachedPlacement?.placementDescription?.subTitle1}
										</ListItem>
										<ListItem disableGutters>
											{attachedPlacement?.placementDescription?.title2}
										</ListItem>
										<ListItem disableGutters>
											{attachedPlacement?.placementDescription?.subTitle2}
										</ListItem>
										<ListItem disableGutters>
											{t('sn')}: {sn}
										</ListItem>
										<ListItem disableGutters>
											{battery && (
												<>
													{`${t('battery')}: `} <BatteryIcon value={battery} />{' '}
													{t('percentage', { value: battery.toFixed(2) })}
												</>
											)}
										</ListItem>
										<ListItem disableGutters>
											{temperature && (
												<>
													{`${t('temperature')}: `}
													{t('degreesCelsius', {
														value: temperature,
													})}
												</>
											)}
										</ListItem>
										<ListItem disableGutters>
											{accuracy && `${t('accuracy')}: ${accuracy}`}
										</ListItem>
										<ListItem disableGutters>
											{time && (
												<>
													{`${t('lastSignal')}: `}
													{format(new Date(time), INPUT_DATE_TIME_MASK)}
												</>
											)}
										</ListItem>
									</List>
								</MapPopup>
							) : (
								<MapPopup
									title={deviceType?.name ?? 'No name'}
									action={
										<Button
											onClick={() => handleRedirectToDeviceDetail(sn)}
											endIcon={<ArrowForwardRounded />}
											variant="text"
											size="small"
										>
											{t('deviceDetail')}
										</Button>
									}
								>
									<List dense disablePadding>
										<ListItem disableGutters>
											{t('sn')}: {sn}
										</ListItem>
										<ListItem disableGutters>
											{battery && (
												<>
													{`${t('battery')}: `} <BatteryIcon value={battery} />{' '}
													{t('percentage', { value: battery.toFixed(2) })}
												</>
											)}
										</ListItem>
										<ListItem disableGutters>
											{temperature && (
												<>
													{`${t('temperature')}: `}
													{t('degreesCelsius', {
														value: temperature,
													})}
												</>
											)}
										</ListItem>
										<ListItem disableGutters>
											{accuracy && `${t('accuracy')}: ${accuracy}`}
										</ListItem>
										<ListItem disableGutters>
											{time && (
												<>
													{`${t('lastSignal')}: `}
													{format(new Date(time), INPUT_DATE_TIME_MASK)}
												</>
											)}
										</ListItem>
										{attachedPlacement && (
											<>
												<ListItem disableGutters>{`${t('placement').toUpperCase()}:`}</ListItem>
												<ListItem disableGutters>
													{attachedPlacement.placementDescription?.title1}
												</ListItem>
												<ListItem disableGutters>
													{attachedPlacement.placementDescription?.subTitle1}
												</ListItem>
											</>
										)}
									</List>
								</MapPopup>
							)}
						</MapMarker>
					);
				}

				return null;
			})}
		</MapContainer>
	);
};
