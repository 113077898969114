import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { Grid } from '@material-ui/core';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { fetchRequest } from '../../../services/helpers';
import { DeviceType, DeviceTypesResponseData } from '../devices-types';
import { fetchDeviceTypes } from '../../../services/devices/devices-service';
import { UUID } from '../../../types';
import { fetchTenants } from '../../../services/user/user-service';
import { Tenant } from '../../Tenants/tenants-types';
import { TenantsResponseData } from '../../../services/user/user-types';

export type CreateDeviceFormValues = {
	id?: UUID;
	sn: string;
	deviceTypeId?: UUID;
	tenantId?: UUID;
	deveui?: string;
	appeui?: string;
	appkey?: string;
	appnkey?: string;
	appskey?: string;
};

type Props = {
	actualUserTenantId?: UUID;
	values?: CreateDeviceFormValues;
	onSubmit: (values: CreateDeviceFormValues) => void;
	onCancel?: () => void;
};

export const CreateDeviceForm = ({ actualUserTenantId, onSubmit, onCancel, values }: Props) => {
	const { t } = useTranslation();

	const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>();

	const [sn, setSn] = useState<string>(values?.sn ?? '');
	const [snError, setSnError] = useState<boolean>(false);

	const [deviceType, setDeviceType] = useState<UUID>(values?.deviceTypeId ?? '');
	const [deviceTypeError, setDeviceTypeError] = useState<boolean>(false);

	const [tenantId, setTenantId] = useState<UUID | undefined>(values?.tenantId);
	const [deveui, setDeveui] = useState<string | undefined>(values?.deveui);
	const [appeui, setAppeui] = useState<string | undefined>(values?.appeui);

	const [appkey, setAppkey] = useState<string | undefined>(values?.appkey);
	const [appnkey, setAppnkey] = useState<string | undefined>(values?.appnkey);
	const [appskey, setAppskey] = useState<string | undefined>(values?.appskey);

	const [tenants, setTenants] = useState<Tenant[]>();

	/**
	 * Fetching all tenants
	 */
	useEffect(() => {
		const request = fetchTenants({
			// Should be enough to get all zones, even for super admin
			limit: 200,
			offset: 0,
			orderings: [],
			filtersAndConditions: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<TenantsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setTenants(data?.data ?? []);
			}
		})();
	}, []);

	useEffect(() => {
		const request = fetchDeviceTypes({
			limit: 100,
			offset: 0,
			orderings: [],
			filtersAndConditions: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<DeviceTypesResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setDeviceTypes(data?.data ?? []);
			}
		})();
	}, []);

	const handleSetSn = (event: ChangeEvent<HTMLInputElement>) => {
		setSn(event.target.value);
		setSnError(false);
	};

	const handleSetDeviceType = (deviceTypeId: UUID) => {
		setDeviceType(deviceTypeId);
		setDeviceTypeError(false);
	};

	const handleSubmitForm = () => {
		let valid = true;

		if (sn === '') {
			valid = false;
			setSnError(true);
		}

		if (deviceType === '') {
			valid = false;
			setDeviceTypeError(true);
		}

		if (valid) {
			if (values?.id) {
				onSubmit({
					id: values.id,
					sn: sn,
					deviceTypeId: deviceType,
					tenantId: tenantId ?? actualUserTenantId,
					deveui: deveui,
					appeui: appeui,
					appkey: appkey,
					appnkey: appnkey,
					appskey: appskey,
				});
			} else {
				onSubmit({
					sn: sn,
					deviceTypeId: deviceType,
					tenantId: tenantId ?? actualUserTenantId,
					deveui: deveui,
					appeui: appeui,
					appkey: appkey,
					appnkey: appnkey,
					appskey: appskey,
				});
			}
		}
	};

	// const selectedDeviceType = deviceTypes.find((deviceType) => deviceType.id === values?.deviceTypeId);

	// console.log(selectedDeviceType);

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputField
					// autoFocus
					value={sn}
					error={snError}
					helperText={snError && t('validationErrors.required')}
					label={t('sn')}
					name="sn"
					onChange={handleSetSn}
				/>
			</Grid>
			<Grid item>
				{deviceTypes && (
					<InputSelectUncontrolled
						defaultValueId={values?.deviceTypeId}
						onChanged={(value) => {
							value && handleSetDeviceType(value.id);
						}}
						error={deviceTypeError}
						options={
							deviceTypes.map((deviceType) => ({
								id: deviceType.id,
								label: deviceType.name,
							})) ?? []
						}
						label={t('selectDeviceType')}
					/>
				)}
			</Grid>
			{actualUserTenantId === undefined && (
				<Grid item>
					{tenants && (
						<InputSelectUncontrolled
							defaultValueId={tenantId ?? actualUserTenantId}
							onChanged={(value) => {
								setTenantId(value?.id);
							}}
							error={false}
							options={
								tenants.map((tenant) => ({
									id: tenant.id,
									label: tenant.name,
								})) ?? []
							}
							label={t('selectTenant')}
						/>
					)}
				</Grid>
			)}
			<Grid item>
				<InputField
					required={false}
					value={deveui}
					label={t('deveui')}
					name="deveui"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setDeveui(event.target.value)}
				/>
			</Grid>
			<Grid item>
				<InputField
					required={false}
					value={appeui}
					label={t('appeui')}
					name="appeui"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setAppeui(event.target.value)}
				/>
			</Grid>
			<Grid item>
				<InputField
					required={false}
					value={appkey}
					label={t('appkey')}
					name="appkey"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setAppkey(event.target.value)}
				/>
			</Grid>
			<Grid item>
				<InputField
					required={false}
					value={appnkey}
					label={t('appnkey')}
					name="appnkey"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setAppnkey(event.target.value)}
				/>
			</Grid>
			<Grid item>
				<InputField
					required={false}
					value={appskey}
					label={t('appskey')}
					name="appskey"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setAppskey(event.target.value)}
				/>
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button onClick={handleSubmitForm} startIcon={<SaveIcon />} data-cy="btn-submit:userRoleForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:userRoleForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};
