import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { CardHeader, Divider, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { TableRowAction, TableRowRenderProps } from '../../../components/Table/types';
import { RootState } from '../../../store/types';
import { Table } from '../../../components/Table/Table';
import { FilterOperators, SortOrder } from '../../../components/Table/constants';
import { useHistory } from 'react-router-dom';
import { RouteEnum } from '../../../router/Routes';
import { fetchRequest } from '../../../services/helpers';
import { UserState } from '../../../store/user/user-types';
import {
	Definition,
	DefinitionResponseData,
	DevicePlacement,
} from '../../DevicePlacementsManagement/device-placements-types';
import { DI_FIELD_LOAD_HANDLING_DEPTH, MHV_DEF_NAME } from '../vehicle-watch-types';
import { fetchDefinitions } from '../../../services/device-placements/device-placements-service';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import RefreshIcon from '@material-ui/icons/Refresh';
import { VehicleWatchState } from '../../../store/vehicle-watch/vehicle-watch-types';
import { fetchVehiclesWithTelemetry } from '../../../store/vehicle-watch/vehicle-watch-async-actions';

const ZONES_INDEX = 2;
const DRIVER_INDEX = 3;

export const MaterialHandlingVehicleTable = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { project } = useSelector<RootState, UserState>((state) => state.user);

	const history = useHistory();

	const [vehicleDefinition, setVehicleDefinition] = useState<Definition | undefined>();

	const { drivers, vehicles } = useSelector<RootState, VehicleWatchState>((state) => state.vehicleWatch);

	const allFields = vehicleDefinition?.placementFields;

	useEffect(() => {
		allFields?.splice(ZONES_INDEX, 0, {
			name: 'zones',
			type: 'zones',
		});

		allFields?.splice(DRIVER_INDEX, 0, {
			name: 'driver',
			type: 'driver',
		});
	}, [allFields]);

	const columns = allFields?.map<{ id: string; label: string }>((field) => ({
		id: field.name,
		label: t(field.name),
	}));

	/**
	 * Fetch vehicle definition
	 */
	useEffect(() => {
		const request = fetchDefinitions({
			// Should be enough to get all definitions
			limit: 0,
			offset: 0,
			orderings: [],
			filtersAndConditions: [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: MHV_DEF_NAME,
				},
			],
		});

		(async () => {
			const { data, error } = await fetchRequest<DefinitionResponseData>(request);

			if (error) {
				console.log(error);
				// TODO show error
			} else {
				if (data && data.data && data.data.length > 0) {
					setVehicleDefinition(data.data[0]);
				}
			}
		})();
	}, [project]);

	useEffect(() => {
		fetchVehiclePlacements();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, vehicleDefinition, project]);

	/**
	 * Fetch vehicle placements
	 */
	function fetchVehiclePlacements() {
		if (vehicleDefinition !== undefined) {
			const filters = [
				{
					column: 'definitionId',
					operator: FilterOperators.equals,
					value: vehicleDefinition.id,
				},
			];

			if (project) {
				filters.push({
					column: 'projects',
					operator: FilterOperators.injoin,
					value: project.id,
				});
			}

			dispatch(
				fetchVehiclesWithTelemetry({
					limit: vehicles?.limit,
					offset: vehicles?.offset,
					orderings: [{ column: 'dateUpdated', sortOrder: SortOrder.Descending }],
					filtersAndConditions: filters,
				}),
			);
		}
	}

	function getDriverDesription(vehicle: DevicePlacement): string | undefined {
		const driverId = vehicle.placementConnections?.find((connection) => connection.detached === null)?.placement2
			.id;

		const driver = drivers.data.find((driver) => driver.id === driverId);

		if (driver) {
			const driverDescription = driver.placementDescription;

			if (driverDescription) {
				return `${driverDescription.title2} ${driverDescription.subTitle2}`;
			}
		}

		return '-';
	}

	function onClickRow(placement: DevicePlacement) {
		history.push(`${RouteEnum.VEHICLE_WATCH}/${placement.id}`);
	}

	const renderRow = ({ row: placement, renderActions }: TableRowRenderProps<DevicePlacement>) => {
		return (
			<TableRow key={placement.id} id={`row-${placement.id}`} onClick={() => onClickRow(placement)}>
				{allFields?.map((field) => {
					if (field.name === 'zones') {
						return (
							<TableCell key={field.name}>
								{placement.telemetry?.zones && placement.telemetry?.zones.length > 0
									? placement.telemetry?.zones[0].name
									: '-'}
							</TableCell>
						);
					}

					if (field.name === 'driver') {
						return <TableCell key={field.name}>{getDriverDesription(placement)}</TableCell>;
					}

					if (field.name === DI_FIELD_LOAD_HANDLING_DEPTH) {
						return (
							<TableCell key={field.name}>
								{placement.telemetry?.pxrdist ? `${placement.telemetry?.pxrdist} cm` : undefined}
							</TableCell>
						);
					}

					const entry = Object.entries(placement.fields).find(([key]) => key === field.name);

					if (entry) {
						return <TableCell key={field.name}>{entry[1] as string}</TableCell>;
					}

					return <TableCell key={field.name}></TableCell>;
				})}
				<TableCell padding="none" sortDirection={false}>
					{renderActions(rowActions, placement)}
				</TableCell>
			</TableRow>
		);
	};

	const rowActions: TableRowAction<DevicePlacement>[] = [];

	return (
		<>
			<CardHeader
				title={t(MHV_DEF_NAME)}
				subheader={
					vehicles?.updatedTime &&
					t('lastUpdate', {
						date: format(new Date(vehicles.updatedTime), INPUT_DATE_TIME_MASK),
					})
				}
				action={
					<Tooltip title={String(t('refresh'))} aria-label={t('refresh')}>
						<IconButton onClick={() => fetchVehiclePlacements()} data-cy="btn-refresh:placements">
							<RefreshIcon />
						</IconButton>
					</Tooltip>
				}
			/>
			<Divider light />

			{vehicles && columns && (
				<Table
					actions={rowActions}
					columns={columns}
					renderTableRow={renderRow}
					fetchAction2={fetchVehiclesWithTelemetry}
					data={vehicles.data}
					dataLimit={vehicles.limit}
					dataOffset={vehicles.offset}
					numberOfRows={vehicles.total}
					orderings={vehicles.orderings}
					filtersAndConditions={vehicles.filtersAndConditions}
					selectable={false}
					sortable={false}
				/>
			)}
		</>
	);
};
