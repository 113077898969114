import { createReducer } from 'typesafe-actions';
import {
	FETCH_PROJECTS_REQUEST,
	FETCH_PROJECTS_SUCCESS,
	FETCH_PROJECTS_ERROR,
	CREATE_PROJECT_REQUEST,
	CREATE_PROJECT_SUCCESS,
	CREATE_PROJECT_ERROR,
	EDIT_PROJECT_REQUEST,
	EDIT_PROJECT_SUCCESS,
	EDIT_PROJECT_ERROR,
	DELETE_PROJECT_REQUEST,
	DELETE_PROJECT_SUCCESS,
	DELETE_PROJECT_ERROR,
	FETCH_PROJECT_INFO_REQUEST,
	FETCH_PROJECT_INFO_ERROR,
	FETCH_PROJECT_INFO_SUCCESS,
} from './projects-constants';

import { ProjectsActions, ProjectsState } from './projects-types';
import { set, filter } from 'lodash/fp';

export const projectsInitialState: ProjectsState = {
	data: [],
	total: 0,
	offset: 0,
	limit: 10,
	orderings: [],
	filters: [],
	projectInfo: { workflowsCount: 0, groupsCount: 0, usersCount: 0, devicesCount: 0 },
};

export const projectsManagementReducer = createReducer<ProjectsState, ProjectsActions>(projectsInitialState, {
	[FETCH_PROJECTS_REQUEST]: (state) => state,
	[FETCH_PROJECTS_SUCCESS]: (state, action) => {
		return {
			...state,
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.limit,
			offset: action.payload.options ? action.payload.options.offset : state.offset,
			orderings: action.payload.options?.orderings || state.orderings,
			filters: action.payload.options?.filtersAndConditions || state.filters,
		};
	},
	[FETCH_PROJECTS_ERROR]: (state) => state,
	[EDIT_PROJECT_REQUEST]: (state) => state,
	[EDIT_PROJECT_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const projectIndex = state.data.findIndex((project) => project.id === data.id);

		return set(`data[${projectIndex}]`, data, state);
	},
	[EDIT_PROJECT_ERROR]: (state) => state,
	[CREATE_PROJECT_REQUEST]: (state) => state,
	[CREATE_PROJECT_SUCCESS]: (state) => state,
	[CREATE_PROJECT_ERROR]: (state) => state,
	[DELETE_PROJECT_REQUEST]: (state) => state,
	[DELETE_PROJECT_SUCCESS]: (state, action) =>
		set(
			'data',
			filter((project) => project.id !== action.payload.id, state.data),
			state,
		),
	[DELETE_PROJECT_ERROR]: (state) => state,
	[FETCH_PROJECT_INFO_REQUEST]: (state) => state,
	[FETCH_PROJECT_INFO_SUCCESS]: (state, action) => set('projectInfo', action.payload.data, state),
	[FETCH_PROJECT_INFO_ERROR]: (state) => state,
});
