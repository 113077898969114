import { apiClient } from '../client';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { UUID } from '../../types';
import {
	CreateReportOrderRequestBody,
	CreateStandingReportOrderRequestBody,
	EditReportOrderRequestBody,
	EditStandingReportOrderRequestBody,
	ReviewDefinition,
	ReportOrdersResponseData,
	StandingReportOrdersResponseData,
	ReviewDefinitionNamesResponseData,
} from '../../pages/Review/review-types';

const baseUrlForDotNet = '/review.net';

export const getFilteredReportOrders = (body: FiltersRequestBody2) =>
	apiClient.post<ReportOrdersResponseData>(`${baseUrlForDotNet}/order/filter`, body);

export const createReportOrder = (body: CreateReportOrderRequestBody) =>
	apiClient.post(`${baseUrlForDotNet}/order`, body);

export const editReportOrder = (body: EditReportOrderRequestBody, reportOrderId: UUID) =>
	apiClient.put(`${baseUrlForDotNet}/${reportOrderId}`, body);

export const deleteReportOrder = (reportOrderId: UUID) => apiClient.delete(`${baseUrlForDotNet}/${reportOrderId}`);

export const getReviewDefinition = (definitionId: UUID) =>
	apiClient.get<ReviewDefinition>(`${baseUrlForDotNet}/definition/${definitionId}`);

export const getReviewDefinitionNames = () =>
	apiClient.get<ReviewDefinitionNamesResponseData>(`${baseUrlForDotNet}/definition/list`);

export const getFilteredStandingReportOrders = (body: FiltersRequestBody2) =>
	apiClient.post<StandingReportOrdersResponseData>(`${baseUrlForDotNet}/filter`, body);

export const createStandingReportOrder = (body: CreateStandingReportOrderRequestBody) =>
	apiClient.post(baseUrlForDotNet, body);

export const editStandingReportOrder = (body: EditStandingReportOrderRequestBody, reportOrderId: UUID) =>
	apiClient.put(`${baseUrlForDotNet}/${reportOrderId}`, body);

export const deleteStandingReportOrder = (reportOrderId: UUID) =>
	apiClient.delete(`${baseUrlForDotNet}/${reportOrderId}`);
