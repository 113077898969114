import React from 'react';
import { makeStyles } from '@material-ui/core';
import { INPUT_DATE_MASK } from '../../helpers/generalConstants';
import { KeyboardDatePickerProps, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Nullable } from '../../types';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useTranslation } from 'react-i18next';
import { addYears, format, subYears } from 'date-fns';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(() => ({
	input: {
		paddingLeft: 0,
	},
}));

// https://material-ui-pickers.dev/api/KeyboardDatePicker
type Props = KeyboardDatePickerProps & {
	autoOk?: boolean;
	disableFuture?: boolean;
	id: string;
	invalidDateMessage?: string;
	mask?: string;
	maxDate?: Date;
	maxDateMessage?: string;
	minDate?: Date;
	minDateMessage?: string;
	onChange: (date: Nullable<Date>, value?: Nullable<string>) => void;
	shouldDisableDate?: (date: Date) => boolean;
	value: ParsableDate;
	variant?: WrapperVariant;
};

export const DatePicker = ({
	autoOk = true,
	disableFuture,
	id,
	invalidDateMessage,
	mask,
	maxDate = addYears(new Date(), 1),
	maxDateMessage,
	minDate = subYears(new Date(), 1),
	minDateMessage,
	onChange,
	shouldDisableDate,
	value,
	variant = 'inline',
	...props
}: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dateMask = mask || INPUT_DATE_MASK;

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				animateYearScrolling
				autoOk={autoOk}
				disableFuture={disableFuture}
				format={dateMask}
				id={id}
				InputAdornmentProps={{ position: 'start', style: { marginRight: 0 } }}
				InputProps={{ className: classes.input }}
				inputVariant="outlined"
				invalidDateMessage={
					invalidDateMessage || t('validationErrors.invalidDateMessage', { format: dateMask })
				}
				maxDate={maxDate}
				maxDateMessage={
					maxDateMessage || t('validationErrors.maxDateMessage', { date: format(maxDate, dateMask) })
				}
				minDate={minDate}
				minDateMessage={
					minDateMessage || t('validationErrors.minDateMessage', { date: format(minDate, dateMask) })
				}
				onChange={onChange}
				shouldDisableDate={shouldDisableDate}
				value={value}
				variant={variant}
				{...props}
			/>
		</MuiPickersUtilsProvider>
	);
};
