// eslint-disable-next-line no-warning-comments

// TODO
export const MHV_DEF_NAME = 'pName_materialHandlingVehicle';
export const DI_DEF_NAME = 'pName_driverId';
export const INDOOR_MAP_ID = '2ec82731-3716-4840-9926-ce93aabe212a';
export const DI_FIELD_PERSONAL_NO = 'fName_di_personalNo';
export const DI_FIELD_FIRST_NAME = 'fName_di_firstName';
export const DI_FIELD_LAST_NAME = 'fName_di_lastName';
export const DI_FIELD_COST_CENTRE = 'fName_di_costCentre';
export const DI_FIELD_LICENCE = 'fName_di_license';
export const DI_FIELD_ATTACHMENT = 'fName_di_attachment';
export const DI_FIELD_LOAD_HANDLING_DEPTH = 'fName_mhv_loadHandlingDepth';

export const DEFAULT_MAP_CENTER: [number, number] = [48.85167015731634, 17.212969064712528]; // Schaefler
