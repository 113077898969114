import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { ProjectsTable } from './components/ProjectsTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { ProjectsState } from '../../store/projects/projects-types';
import { fetchProjects } from '../../store/projects/projects-async-actions';
import { Grid } from '@material-ui/core';
import { UserState } from '../../store/user/user-types';

export const Projects = () => {
	const dispatch = useDispatch();
	const { data, total, limit, offset, filters, orderings } = useSelector<RootState, ProjectsState>(
		(state) => state.projects,
	);

	const { project } = useSelector<RootState, UserState>((state) => state.user);

	useEffect(() => {
		// TODO is this necessary?
		if (project?.id) {
			dispatch(
				fetchProjects({
					limit: limit,
					offset: offset,
					orderings: orderings,
					filtersAndConditions: filters,
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<ProjectsTable
						data={data}
						total={total}
						offset={offset}
						limit={limit}
						orderings={orderings}
						filters={filters}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};
