import React, { useEffect } from 'react';
import { Table } from '../../../components/Table/Table';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	HideFlag,
	TableRowRenderProps,
	TableRowAction,
} from '../../../components/Table/types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import {
	TableRow,
	TableCell,
	CardHeader,
	Divider,
	makeStyles,
	FormControlLabel,
	Checkbox,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { DeviceResponseData2 } from '../devices-types';
import {
	fetchDevicesWithTelemetry,
	fetchDeviceTableHidenColumns,
	setDeviceTableHidenColumns,
} from '../../../store/devices/devices-async-actions';
import { useHistory } from 'react-router-dom';
import { RouteEnum } from '../../../router/Routes';
import clsx from 'clsx';
import { ViewColumnRounded, Visibility } from '@material-ui/icons';
import { PopoverOptions } from '../../../components/Table/PopoverOptions';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '../../../store/user/user-types';
import { RootState } from '../../../store/types';
import { addModalDialog } from '../../../store/app/app-actions';

type Props = {
	data: DeviceResponseData2[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filters: TableColumnFilter[];
	updatedTime?: string;
};

const useStyles = makeStyles({
	hidden: {
		display: 'none',
	},
	row: {
		background: '#f7f7f7',
	},
});

export const DevicesTable = ({ data, total, offset, limit, orderings, filters, updatedTime }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { userId } = useSelector<RootState, UserState>((state) => state.user);

	const DEVICES_HEAD_CELLS_KEY = `DevicesHideKey4${userId}`;

	const snOrder = orderings?.find((order) => order.column === 'sn');
	const snFilter = filters?.find((filter) => filter.column === 'sn');

	const euiOrder = orderings?.find((order) => order.column === 'DeviceTelemetry.data.eui');
	const euiFilter = filters?.find((filter) => filter.column === 'DeviceTelemetry.data.eui');

	const groupsOrder = orderings?.find((order) => order.column === 'DeviceGroups.count');

	const typeOrder = orderings?.find((order) => order.column === 'deviceType.name');
	const typeFilter = filters?.find((filter) => filter.column === 'deviceType.name');

	const batteryOrder = orderings?.find((order) => order.column === 'DeviceTelemetry.data.battery');

	const lastSeenOrder = orderings?.find((order) => order.column === 'DeviceTelemetry.data.time');
	const lastSeenFilters = filters?.filter((filter) => filter.column === 'DeviceTelemetry.data.time');

	function onClickRow(device: DeviceResponseData2) {
		history.push(`${RouteEnum.DEVICES}/${device.sn}`);
	}

	const rowActions: TableRowAction<DeviceResponseData2>[] = [
		{
			id: 'btn-detail:telemetry',
			icon: TableRowActionIcon.show,
			tooltip: t('telemetryJson'),
			onClick: (device: DeviceResponseData2) =>
				dispatch(
					addModalDialog({
						id: 'telemetryJson',
						title: 'Telemetry JSON',
						content: <pre>{JSON.stringify(device.data, null, 2)}</pre>,
					}),
				),
		},
		{
			id: 'btn-detail:telemetry',
			icon: TableRowActionIcon.detail,
			tooltip: t('detail'),
			onClick: (device: DeviceResponseData2) => onClickRow(device),
		},
	];

	const defaultColumns: TableHeadCell[] = [
		{
			id: 'sn',
			label: 'sn',
			filters: snFilter && [snFilter],
			filterType: FilterType.String,
			sort: snOrder?.sortOrder && { column: 'sn', sortOrder: snOrder?.sortOrder },
		},
		{
			id: 'DeviceTelemetry.data.eui',
			label: 'networkNumber',
			filters: euiFilter && [euiFilter],
			filterType: FilterType.String,
			sort: euiOrder?.sortOrder && { column: 'DeviceTelemetry.data.eui', sortOrder: euiOrder?.sortOrder },
		},
		{
			id: 'deviceType.name',
			label: 'type',
			filters: typeFilter && [typeFilter],
			filterType: FilterType.String,
			sort: typeOrder?.sortOrder && { column: 'deviceType.name', sortOrder: typeOrder?.sortOrder },
		},
		{
			id: 'DeviceGroups.count',
			label: 'deviceGroups',
			sort: groupsOrder?.sortOrder && { column: 'DeviceGroups.count', sortOrder: groupsOrder?.sortOrder },
		},
		{
			id: 'DeviceTelemetry.data.battery',
			label: 'battery',
			sort: batteryOrder?.sortOrder && {
				column: 'DeviceTelemetry.data.battery',
				sortOrder: batteryOrder?.sortOrder,
			},
		},
		{ id: 'signalPower', label: 'signalPower', hidden: true, unorderable: true }, // Temporarily hidden
		{
			id: 'DeviceTelemetry.data.time',
			label: 'lastMessage',
			filters: lastSeenFilters,
			filterType: FilterType.DateSpan,
			sort: lastSeenOrder?.sortOrder && {
				column: 'DeviceTelemetry.data.time',
				sortOrder: lastSeenOrder?.sortOrder,
			},
		},
		{
			id: 'DeviceTelemetry.data.time',
			label: 'sinceConnected',
			sort: lastSeenOrder?.sortOrder && {
				column: 'DeviceTelemetry.data.time',
				sortOrder: lastSeenOrder?.sortOrder,
			},
		},
		{ id: 'zones', label: 'location', unorderable: true },
		{ id: 'moved', label: 'moved', hidden: true, unorderable: true },
		{
			id: 'hasMovedWithoutNewLocation',
			label: 'hasMovedWithoutNewLocation',
			hidden: true,
			unorderable: true,
		},
		{ id: 'locationUpdateDetails', label: 'locationUpdateDetails', hidden: true, unorderable: true },
		{ id: 'temperature', label: 'temperature', hidden: true, unorderable: true },
		{ id: 'lightIntensity', label: 'lightIntensity', hidden: true, unorderable: true },
		{ id: 'accuracy', label: 'accuracy', hidden: true, unorderable: true },
		{ id: 'maxAcceleration', label: 'maxAcceleration', hidden: true, unorderable: true },
		{ id: 'maxAccelerationHistory', label: 'maxAccelerationHistory', hidden: true, unorderable: true },
		{ id: 'latitude', label: 'latitude', hidden: true, unorderable: true },
		{ id: 'longitude', label: 'longitude', hidden: true, unorderable: true },
		{ id: 'bizStep', label: 'bizStep', hidden: true, unorderable: true },
		{ id: 'action', label: 'action', hidden: true, unorderable: true },
		{ id: 'eventType', label: 'eventType', hidden: true, unorderable: true },
		{ id: 'actDescription', label: 'actDescription', hidden: true, unorderable: true },
		{ id: 'eventTime', label: 'eventTime', hidden: true, unorderable: true },
	];

	const columns = useSelector<RootState, TableHeadCell[]>((state) => {
		const storedColumns = state.devices.deviceTableHidenFlags;

		const isValid = Array.isArray(storedColumns) && storedColumns.length > 0;

		if (isValid) {
			const tmp = storedColumns as HideFlag[];

			defaultColumns.forEach((column) => {
				const hasFilter = (column.filters && column.filters.length > 0) ?? false;

				if (hasFilter) {
					column.hidden = false;
				} else {
					const col = tmp.find((co) => co.id === column.id);

					if (col) {
						column.hidden = col.hidden;
					}
				}
			});
		}

		return defaultColumns;
	});

	function storeHideFlags(id: string) {
		const changedColumns = columns.map((column) =>
			column.id === id
				? {
						id: column.id,
						hidden: column.hidden ? !column.hidden : true,
				  }
				: {
						id: column.id,
						hidden: column.hidden ?? false,
				  },
		);

		dispatch(
			setDeviceTableHidenColumns({
				key: DEVICES_HEAD_CELLS_KEY,
				body: changedColumns,
			}),
		);
	}

	useEffect(() => {
		// console.log('FETCH HIDE FLAGS');
		// dispatch(fetchDeviceTableHidenColumns(DEVICES_HEAD_CELLS_KEY));
		dispatch(fetchDeviceTableHidenColumns(DEVICES_HEAD_CELLS_KEY));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const shouldHideColumn = (columnId: string) => columns.some(({ id, hidden }) => columnId === id && hidden);

	const now = new Date().getTime();

	// TODO move to some help class
	const getDifference = (date: Date) => {
		const diffInMs = Math.abs(date.getTime() - now);

		const seconds = Math.floor(diffInMs / 1000);

		const minutes = Math.floor(seconds / 60);

		const hours = Math.floor(minutes / 60);

		const days = Math.floor(hours / 24);

		const s = seconds - minutes * 60;

		const m = minutes - hours * 60;

		const h = hours - days * 24;

		if (days === 0) {
			if (h === 0) {
				return `${m}m ${s}s`;
			}

			return `${h}h ${m}m ${s}s`;
		}

		return `${days}d ${h}h ${m}m ${s}s`;
	};

	const renderTableRow = ({ row: device, renderActions }: TableRowRenderProps<DeviceResponseData2>) => {
		return device ? (
			<TableRow
				key={device.id}
				id={`row-${device.sn}`}
				hover
				onClick={() => onClickRow(device)}
				className={clsx(
					(device.deviceGroups?.$values === undefined || device.deviceGroups.$values.length === 0) &&
						classes.row,
				)}
			>
				<TableCell
					className={clsx(shouldHideColumn('sn') && classes.hidden)}
					component="td"
					id="sn"
					scope="row"
				>
					{device.sn}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('eui') && classes.hidden)}
					component="td"
					id="eui"
					scope="row"
				>
					{device.data?.eui}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('type') && classes.hidden)}
					component="td"
					id="type"
					scope="row"
				>
					{device.deviceType?.name}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('DeviceGroups.Count') && classes.hidden)}
					component="td"
					id="DeviceGroups.Count"
					scope="row"
				>
					{device.deviceGroups?.$values?.map((group) => group.name)}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('DeviceTelemetry.Data.battery') && classes.hidden)}
					component="td"
					id="DeviceTelemetry.Data.battery"
					scope="row"
				>
					{device.data?.battery && t('percentage', { value: device.data.battery.toFixed(2) })}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('signalPower') && classes.hidden)}
					component="td"
					id="signalPower"
					scope="row"
				>
					{device.data?.signal_power}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('lastSeen') && classes.hidden)}
					component="td"
					id="lastSeen"
					scope="row"
				>
					{device.data?.time && format(new Date(device.data.time), INPUT_DATE_TIME_MASK)}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('sinceConnected') && classes.hidden)}
					component="td"
					id="sinceConnected"
					scope="row"
				>
					{device.data?.time && getDifference(new Date(device.data.time))}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('zones') && classes.hidden)}
					component="td"
					id="zones"
					scope="row"
				>
					{Array.isArray(device.data?.zones) &&
						device.data?.zones.map((zone, index: number) => `${index > 0 ? ', ' : ''}${zone.name}`)}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('moved') && classes.hidden)}
					component="td"
					id="moved"
					scope="row"
				>
					{t(`${device.data?.moved}`)}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('locationUpdateDetails') && classes.hidden)}
					component="td"
					id="locationUpdateDetails"
					scope="row"
				>
					{device.data?.location_update_details}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('temperature') && classes.hidden)}
					component="td"
					id="temperature"
					scope="row"
				>
					{device.data?.temperature && t('degreesCelsius', { value: device.data.temperature.toFixed(2) })}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('lightIntensity') && classes.hidden)}
					component="td"
					id="lightIntensity"
					scope="row"
				>
					{device.data?.light_intensity && t('illuminance', { value: device.data.light_intensity })}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('accuracy') && classes.hidden)}
					component="td"
					id="accuracy"
					scope="row"
				>
					{device.data?.accuracy}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('maxAcceleration') && classes.hidden)}
					component="td"
					id="maxAcceleration"
					scope="row"
				>
					{device.data?.max_acceleration}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('maxAccelerationHistory') && classes.hidden)}
					component="td"
					id="maxAccelerationHistory"
					scope="row"
				>
					{device.data?.max_acceleration_history}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('latitude') && classes.hidden)}
					component="td"
					id="latitude"
					scope="row"
				>
					{device.data?.lat}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('longitude') && classes.hidden)}
					component="td"
					id="longitude"
					scope="row"
				>
					{device.data?.lng}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('bizStep') && classes.hidden)}
					component="td"
					id="bizStep"
					scope="row"
				>
					{device.data?.biz_step}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('action') && classes.hidden)}
					component="td"
					id="action"
					scope="row"
				>
					{device.data?.action}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('eventType') && classes.hidden)}
					component="td"
					id="eventType"
					scope="row"
				>
					{device.data?.eventType}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('actDescription') && classes.hidden)}
					component="td"
					id="actDescription"
					scope="row"
				>
					{device.data?.act_description}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('eventTime') && classes.hidden)}
					component="td"
					id="eventTime"
					scope="row"
				>
					{device.data?.eventTime}
				</TableCell>
				<TableCell id="actions" padding="none" sortDirection={false} align="right">
					<TableActionsContainer>{renderActions(rowActions, device)}</TableActionsContainer>
				</TableCell>
			</TableRow>
		) : null;
	};

	// console.log(columns);

	return (
		<>
			<CardHeader
				title={t('devices')}
				// subheader={t('devicesLastTelemetry')}
				subheader={
					updatedTime &&
					t('lastUpdate', {
						date: format(new Date(updatedTime), INPUT_DATE_TIME_MASK),
					})
				}
				action={
					<>
						<Tooltip title={'Devices JSON'} aria-label={t('JSON')}>
							<IconButton
								onClick={() =>
									dispatch(
										addModalDialog({
											id: 'devicesJson',
											title: 'Devices JSON',
											content: <pre>{JSON.stringify(data, null, 2)}</pre>,
										}),
									)
								}
								data-cy="btn-show:devicesJson"
							>
								<Visibility />
							</IconButton>
						</Tooltip>
						<PopoverOptions
							id="devicesTable-columnsSettings"
							icon={<ViewColumnRounded />}
							title={t('showHideColumns')}
						>
							{defaultColumns.map(({ id, label, filters }) => {
								const isChecked = columns.some((column) => column.id === id && !column.hidden);
								const isDisabled = filters && filters.length > 0;

								return (
									<FormControlLabel
										key={id}
										control={
											<Checkbox
												disabled={isDisabled}
												checked={isChecked}
												color="default"
												name={id}
												onChange={() => storeHideFlags(id)}
											/>
										}
										label={t(label)}
									/>
								);
							})}
						</PopoverOptions>
						{/* <DevicesTableFilter
							config={options.config}
							filters={options.filters as DevicesTelemetryFilters}
						/> */}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={rowActions}
				columns={columns.map((column) => {
					return { ...column, label: t(column.label) };
				})}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				numberOfRows={total}
				fetchAction2={fetchDevicesWithTelemetry}
				renderTableRow={renderTableRow}
				selectable={false}
				size="small"
			/>
		</>
	);
};
